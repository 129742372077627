import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import $ from "jquery";
import url from '../../assets/url';
import Geocode from "react-geocode";
// import GoogleMapReact from 'google-map-react';
import LottieLoader from '../LottieLoader';
import { isMobile } from "react-device-detect";
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import TermsCondition from '../TermsCondition/index';
import Footer from '../innerpages/footer';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";

const AnyReactComponent = ({ text }) => <div><img src={require('../../assets/images/marker.png')} width="20px" alt="" title="Marker" /></div>;
// const AnyReactComponent = ({ text }) => <div>{text}</div>;
class Perksdetailall extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            active: false,
            modal: false,
            show_detail: false,
            modal_map: false,
            detail: {},
            online: false,
            dynamic_content: "",
            current_perk: {},
            show_t_and_c: false,
            redeem: false,
            loader: false,
            places_lat_long: [],
            show_map: true,
            cookie_auto_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id") ? localStorage.getItem("auto_id") : null
        };
        this.toggleMap = this.toggleMap.bind(this);
        this.openAccounts = this.openAccounts.bind(this);
        this.kidsgofreecode = this.kidsgofreecode.bind(this);
    }

    openAccounts() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleRedeem = () => {
        let action = "User clicked on redeem button on perksonlinecode page"
        let image = 'redeem_button_perksonlinecode_page.png'
        functions.logUserActivity(action, image);
        let perk_id = this.state.detail.auto_id
        let redeem_type = 'online'
        functions.insertInPerks(this, perk_id, redeem_type, function (json_reponse) {
            let response = JSON.parse(json_reponse);
        })
    }

    clickOnline = () => {
        let { auto_id, assigned_coupon } = this.state.detail
        let { detail } = this.state
        if (auto_id !== "54391057" && auto_id !== "69393410" && auto_id!=="44888985") {
            this.openAccounts()
        }
        let self = this

        localStorage.setItem("perks_detail", JSON.stringify(this.state.detail))

        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");

        if (this.state.detail.auto_id === "69393410") {
            this.handleRedeem();

            let fintelApiUrl = `https://api.fintelconnect.com/t/l/finteltag/a-28153b-25049c-${sub_id}`;
            window.open(fintelApiUrl);
        } else {
            if (!assigned_coupon && (auto_id == "54391057" || auto_id == "69393410" || auto_id == "44888985") && !detail.coupon_code) {
                functions.updateUsercoupon(auto_id, sub_id, function (callback) {
                    let respo = JSON.parse(callback)
                    if (respo.data.coupon_code) detail["coupon_code"] = respo.data.coupon_code
                    self.updatePerk(detail)
                    functions.pushToRoute(self, 'perksonlinecode', 'perks_detail')
                })
            }
            else {
                functions.pushToRoute(self, 'perksonlinecode', 'perks_detail')
            }


        }
    }

    updatePerk = (detail, category, index) => {
        let perk_list = localStorage.getItem("perks_detail") ? JSON.parse(localStorage.getItem("perks_detail")) : localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
        perk_list = detail
        localStorage.setItem("perks_detail", JSON.stringify(perk_list))
    }
    componentDidMount = () => {
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
   
        if (localStorage.getItem("current_state") != "perks_detail" && !auto_id ) {
         // Redirect to "/" if current_state is not "story_detail"
         this.props.history.push("/");
         // Reload the window to ensure any necessary updates or resets
         window.location.reload();
        }
       
        let detail = this.props.detail
        let previous_path = localStorage.getItem("previous_state")
        if (!detail || detail == undefined) {
            let perk_list = localStorage.getItem("perk_response") && localStorage.getItem("perk_response") !== undefined ? JSON.parse(localStorage.getItem("perk_response")) : localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
            let entered_id = localStorage.getItem("entered_id") && localStorage.getItem("entered_id") !== undefined ? JSON.parse(localStorage.getItem("entered_id")) : localStorage.getItem("session_entered_id") ? JSON.parse(localStorage.getItem("session_entered_id")) : "";
            detail = perk_list.find(pro => pro.auto_id == entered_id)
        }
        let dynamic_content = localStorage.getItem("dynamic_content") && localStorage.getItem("dynamic_content") !== undefined ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            let old_enter_terms_policy = dynamic_content.static_text.perksdetails.enter_terms_policy.en
            let color = this.state?.detail?.is_upgraded == 1 ? detail.detail_custom.terms_color : ""
            let new_enter_terms_policy = old_enter_terms_policy.replace('<span style="%COLOR_STYLE%">', '<span class ="perk_terms" id = ' + detail?.auto_id + 'style=' + color + '>')
            new_enter_terms_policy = old_enter_terms_policy.replace("%TERMS%", detail?.terms || '')
            dynamic_content.static_text.perksdetails.enter_terms_policy.en = new_enter_terms_policy
            this.setState({ dynamic_content: dynamic_content })
        }
        let self = this;
        window.scrollTo(0, 1);

        this.setState({ detail: detail, show_detail: true })

        this.handleTermsClick()
    }

    handleUnlock = () => {
        let success_action = "User clicked on unlock button on perksdetail page"
        let image = 'unlock_button_perkdetail_page.png'
        functions.logUserActivity(success_action, image);
        let self = this
        let perk_id = this.state.detail.auto_id
        let redeem_type = 'unlocked'
        this.state.detail.locked = 0;
        localStorage.setItem("perks_detail", JSON.stringify(this.state.detail))
        self.setState({ loader: true })
        this.updateIsUpgrade(perk_id, 'is_locked')
        functions.insertInPerks(this, perk_id, redeem_type, function (json_reponse) {
            let response = json_reponse && JSON.parse(json_reponse);
            self.setState({ loader: false })
            if (response.success == 1) {
                localStorage.setItem("bar_code", response.data.auto_id)
            }
        })
    }

    clickUpgrade = () => {
        let success_action = "User clicked on upgrade button on perksdetail page"
        let image = 'upgrade_button_perkdetail_page.png'
        functions.logUserActivity(success_action, image);
        let self = this
        this.openAccounts()
        let perk_id = this.state.detail.auto_id
        let redeem_type = 'upgrade'
        this.state.detail.is_upgraded = 1;
        localStorage.setItem("perks_detail", JSON.stringify(this.state.detail))
        self.setState({ loader: true })
        this.updateIsUpgrade(perk_id, 'is_upgraded')
        functions.insertInPerks(this, perk_id, redeem_type, function (json_reponse) {
            let response = JSON.parse(json_reponse);
            self.setState({ loader: false })
            if (response.success == 1) {
                localStorage.setItem("bar_code", response.data.auto_id)
            }
        })
    }

    updateIsUpgrade = (perk_id, state) => {
        if (state == 'is_locked') {
            let perk_data = this.state.All
            var index = perk_data.findIndex(p => p.auto_id == perk_id)
            perk_data[index].locked = 0;
            let temp_perk = perk_data[index]
            let temp = this.state[temp_perk.category]
            temp.locked = 0;
            this.setState({ perk_data: perk_data })
            this.setState({ [temp_perk.category]: temp })
        } else if (state == 'is_upgraded') {
            let perk_data = this.state.All
            var index = perk_data.findIndex(p => p.auto_id == perk_id)
            perk_data[index].is_upgraded = 1;
            let temp_perk = perk_data[index]
            let temp = this.state[temp_perk.category]
            temp.is_upgraded = 1;
            this.setState({ perk_data: perk_data })
            this.setState({ [temp_perk.category]: temp })
        }
    }

    clickInStore = () => {
        let success_action = "User clicked on instore button on perksdetail page"
        let image = 'instore_button_perksdetail_page.png'
        functions.logUserActivity(success_action, image);
        let self = this
        let perk_id = this.state.detail.auto_id
        let redeem_type = 'in-store'
        localStorage.setItem("perks_detail", JSON.stringify(this.state.detail))
        this.setState({ loader: true })
        functions.insertInPerks(this, perk_id, redeem_type, function (json_reponse) {
            let response = JSON.parse(json_reponse);
            self.setState({ loader: false })
            if (response.success == 1) {
                localStorage.setItem("bar_code", perk_id)
                functions.pushToRoute(self, 'perksinstore', 'perks_detail')
            }
        })
    }

    toggleMap() {
        this.setState({
            modal_map: !this.state.modal_map
        });
    }

    kidsgofreecode() {
        this.props.history.push('/kidsgofreecode');
    }

    closeToggle(value, state) {
        value.setState({
            [state]: !value.state[state]
        });
    }

    mapsSelector(lat, lng) {
        if (isMobile) {
            if /* if we're on iOS, open in Apple Maps */
                ((navigator.platform.indexOf("iPhone") != -1) ||
                (navigator.platform.indexOf("iPad") != -1) ||
                (navigator.platform.indexOf("iPod") != -1)) {
                window.open("maps://maps.google.com/maps?daddr=" + lat + "," + lng + "&amp;ll=");
            }
            else {
                window.open("http://maps.google.com/maps?daddr=" + lat + "," + lng + "&amp;ll=");
            }
        }
    }

    handleGetDeal = () => {
        let success_action = "User clicked on getdeal button on perksdetail page"
        let image = 'getdeal_button_perksdetail_page.png'
        functions.logUserActivity(success_action, image);
        let detail = this.state.detail
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id != "") {
            if (detail.instore_offer == 1 && detail.online_offer == 1) {
                this.openAccounts()
            } else if (detail.instore_offer == 1) {
                this.clickInStore()
            } else if (detail.online_offer == 1) {
                this.clickOnline()
            }
        }
        else {
            localStorage.setItem("session_entered_id", detail.auto_id)
            localStorage.setItem("perks_or_main", "perks")
            this.props.history.push({
                pathname: '/home'
            })
        }
    }

    handleBack = () => {
        let success_action = "User clicked on back button on perksdetail page"
        let image = 'back_button_perksdetail_page.png'
        functions.logUserActivity(success_action, image);
        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id != "") {
            this.props.props.history.push("/perks")
        }
        else {
            this.props.props.history.push("/")
        }
    }

    handleTerms = (perk) => {
        let success_action = "User clicked on terms button on perksdetail page"
        let image = 'terms_button_perksdetail_page.png'
        functions.logUserActivity(success_action, image);
        perk.term_and_condition = perk.terms
        this.setState({ current_perk: perk, show_t_and_c: true });
    }

    handleCloseTerms = () => {
        this.setState({ show_t_and_c: false })
    }

    handleBackSession = () => {
        let action = "User clicked on back button of " + this.state.detail.auto_id + " on perksdetail page"
        let image = 'back_button_perksdetail_page_' + this.state.detail.auto_id + '.png'
        functions.logUserActivity(action, image);
        let previous_state = localStorage.getItem("previous_state")
        localStorage.setItem("detail_page", 'false')
        if ((previous_state == "main_index" || previous_state == 'perksonlinecode' || previous_state == 'perksinstore') && (localStorage.getItem("name") || localStorage.getItem("email"))) {
            functions.pushToRoute(this, 'main', 'perks_detail')
        } else if (previous_state == "main_index") {
            functions.pushToRoute(this, "", "perks_detail")
        }
        else if (previous_state == "perks") {
            functions.pushToRoute(this, "perks", "perks_detail")
        }
    }

    handleTermsClick = () => {
        let self = this;
        $(document).on('click', '.perk_terms', function () {
            self.handleTerms(self.state.detail)
        })
    }

    render() {
        const { dynamic_content, detail } = this.state
        const closeModel = <button className="closeModel" onClick={() => { this.closeToggle(this, 'modal') }}>&times;</button>;
        return (
            <div id={"perks_" + this.state.detail?.auto_id} className={"program-page hero-1 hero_" + this.state.detail?.auto_id}>
               <div className="common_mainAddOn">
                <div className="common_main detailspace_rmv" style={{ background: this.state?.detail?.is_upgraded == 1 ? this.props.active == false ? this.state.detail.detail_custom.background : "" : "" }}>
                    {this.state.loader &&
                        <center>
                            <LottieLoader className="loader_class" />
                        </center>
                    }
                    {this.state.show_detail && !this.state.loader && this.state.dynamic_content != "" &&
                        <div className="perks_dtl" style={{ paddingBottom: "40px", background: this.state.detail && this.state?.detail?.is_upgraded == 1 ? this.state.detail.detail_custom.background : "", borderRadius: this.props.active == true ? "0px" : "" }}>

                            <div className="perks_dtlImg">
                                <div onClick={() => { this.handleBackSession() }} className="sharegain_backlink profileBkImg">
                                    <div>
                                        <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                    </div>
                                    <div className="detailback_white">
                                    </div>
                                </div>

                                <div className="perks_img" style={{ borderRadius: this.props.active == true ? "0px" : "" }}>
                                    <img style={{ borderRadius: this.props.active == true ? "0px" : "" }} src={url.cloudflare_image_path + this.state.detail?.perk_image + url.varient} alt="" title="" />
                                    <a className="playvideo">
                                        {this.state.detail && this.state.detail.video_url && this.state.detail.video_url.length > 2 &&
                                            <img src={require('../../assets/images/Subtractplay.png')} alt="" title="" />
                                        }
                                    </a>
                                </div>

                                <div className="perksImgsNw">
                                    <div className="perksImgs program_detail_logo">
                                        {/* {this.state.detail.logo && this.state.detail.logo.map((logo1) => (
                                            <a key={logo1}>
                                                <img src={url.cloudflare_image_path + logo1} alt="" title="" />
                                            </a>
                                        ))} */}
                                    </div>
                                </div>
                            </div>

                            <div className="perks_descp">
                                <div className="family_fun rom_collection 8989">
                                    {/* <Button size="medium" color="primary" style={{
                                        background: this.state?.detail?.is_upgraded == 1 ? this.state.detail.card_tab_background : this.state.detail.active_menu_tab_background,
                                        color: this.state?.detail?.is_upgraded == 1 ? this.state.detail.card_tab_text_color : this.state.detail.active_menu_tab_text_color
                                    }}>
                                        {this.state.detail.category}
                                    </Button> */}
                                  <div
  className="perksTagBtn"
  style={{
    background: this.state?.detail && this.state.detail.is_upgraded == 1 
      ? this.state.detail.card_tab_background 
      : this.state.detail?.active_menu_tab_background,
    color: this.state?.detail && this.state.detail.is_upgraded == 1 
      ? this.state.detail.card_tab_text_color 
      : this.state.detail?.active_menu_tab_text_color,
  }}
>
  <img src={require('../../assets/images/perkIcon.png')} alt="" title="" />
  {this.state?.detail?.category || ""}
</div>
{this.props.auto_id !== "" &&
  this.state?.detail &&
  this.state.detail.upgrade == 1 &&
  this.state.detail.is_upgraded == 0 && (
    <Button size="medium" color="primary" className="upgrade_btn">
      <img src={require('../../assets/images/Group0201.png')} alt="" title="" />
      {dynamic_content.static_text.perksdetails.upgrade.en}
    </Button>
)}

                                    
                                    {this.props.auto_id != "" && this.state.detail.is_locked == 1 && this.state.detail.locked == 1 &&
                                        <Button size="medium" color="primary" className="unlock_btn"> <img src={require('../../assets/images/Group01190.png')} alt="" title="" /> {dynamic_content.static_text.perksdetails.locked.en} </Button>
                                    }
                                    {this.props.auto_id != "" && this.state.detail.is_locked == 1 && this.state.detail.locked == 0 &&
                                        <Button size="medium" color="primary" className="unlock_btn"> <img src={require('../../assets/images/upgrade-lock-icon-white.png')} alt="" title="" /> {dynamic_content.static_text.perksdetails.unlocked.en} </Button>
                                    }
                                    {/* new upgrade Button */}
                                    {this.props.auto_id != "" && this.state?.detail?.is_upgraded == 1 && this.state.detail.upgrade == 1 &&
                                        <Button size="medium" style={{ background: "#f0f0f0" }} className="upgradeRght_btn"><img src={require('../../assets/images/newArows.png')} alt="" title="" />{dynamic_content.static_text.perksdetails.upgraded.en}</Button>
                                    }{/* new Button */}
                                </div>
                                <div className="perks_dtl_iner program_title01">
                                    <label style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.title_color } : {}}>{this.state?.detail?.is_upgraded == 1 ? this.state.detail.upgrade_value.title : this.state.detail.title}</label>
                                    <div style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.title_color, opacity: "0.5" } : {}}>{this.state?.detail?.is_upgraded == 1 ? this.state.detail.upgrade_value.subtitle : this.state.detail.subtitle}</div>
                                </div>
                                <div className="perks_allCstm">
                                    <label style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.title_color } : {}}>Description</label>
                                    <div className='perks_allInrCstm' style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.title_color, opacity: "0.5" } : {}}>{ReactHtmlParser(this.state.detail.description)}</div>
                                </div>
                                <div className="deal_redem">
                                    {this.props.auto_id == "" &&
                                        <Button style={this.state.detail && this.state?.detail?.is_upgraded ? { background: this.state.detail.detail_custom.button_color } : {}} size="medium" color="primary" onClick={() => { this.handleGetDeal() }} >
                                            {detail.auto_id !== "69393410" ? dynamic_content.static_text.perksdetails.get_a_deal.en : "Get a Free Quote"}
                                        </Button>
                                    }
                                    {this.props.auto_id != "" && this.state.detail.is_locked == 0 &&
                                        <Button style={this.state.detail && this.state?.detail?.is_upgraded ? { background: this.state.detail.detail_custom.button_color } : {}} size="medium" color="primary" onClick={() => { this.handleGetDeal() }} >
                                            {detail.auto_id !== "69393410" ? dynamic_content.static_text.perksdetails.get_a_deal.en : "Get a Free Quote"}
                                        </Button>
                                    }
                                    
                                    {this.props.auto_id != "" && this.state.detail.is_locked == 1 && this.state.detail.locked == 0 &&
                                        <Button style={this.state.detail && this.state?.detail?.is_upgraded ? { background: this.state.detail.detail_custom.button_color } : {}} size="medium" color="primary" onClick={() => { this.handleGetDeal() }} >
                                            {detail.auto_id !== "69393410" ? dynamic_content.static_text.perksdetails.get_a_deal.en : "Get a Free Quote"}
                                        </Button>
                                    }
                                    {this.props.auto_id != "" && this.state.detail.is_locked == 1 && this.state.detail.locked == 1 &&
                                        <Button style={{ background: "#634C75" }} size="medium" color="primary" onClick={() => { this.handleUnlock() }} >
                                            {dynamic_content.static_text.perksdetails.unlock.en}
                                        </Button>
                                    }
                                </div>

                                {this.state.detail.auto_id !== "54391057" && this.state.detail.auto_id !== "69393410" && this.state.auto_id !== "44888985" &&
                                    <div className="deal_frrebies deal_frrebiesCstm">
                                        <div style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.terms_color } : {}}>By entering you are agreeing to the <span className={ this.state.detail && this.state?.detail?.is_upgraded == 1 ? "" : "terms_all"} style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.terms_color } : {}} onClick={() => { this.handleTerms(this.state.detail) }}>Terms and Conditions</span> below. <span>{ReactHtmlParser(this.state.detail.terms)}</span></div>
                                    </div>
                                }
                                {this.state.detail.auto_id !== "54391057" && this.state.detail.auto_id !== "69393410" && this.state.auto_id !== "44888985"  &&
                                    <div className="perks_loc">
                                        {this.state.detail.places_lat_long && this.state.detail.places_lat_long.length > 0 && <label style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.title_color } : {}}>Location</label>}
                                        {this.state.detail.perk_location && this.state.detail.perk_location.length > 0 && this.state.detail.perk_location.map((element, index) => (
                                            <div style={this.state.detail && this.state?.detail?.is_upgraded == 1 ? { color: this.state.detail.detail_custom.title_color, opacity: "0.5" } : {}} key={index}>{element != "Address need to update" && element != "Address need to be update" ? element : ""}</div>
                                        ))}
                                    </div>
                                }

                                {/* <div className="map_location paddingbottom">
                                    {this.state.show_map && this.state.detail.places_lat_long && this.state.detail.places_lat_long.length > 0 &&

                                        <div style={{ height: '200px', width: '100%' }}>
                                            <GoogleMapReact
                                                bootstrapURLKeys={{ key: "AIzaSyA10e_yHodj3O1MbN_bOBCPDnnP6GPCeN8" }}
                                                defaultCenter={{ lat: this.state.detail.places_lat_long[0].lat, lng: this.state.detail.places_lat_long[0].lng }}
                                                defaultZoom={11}
                                            >
                                                {this.state.detail.places_lat_long && this.state.detail.places_lat_long.length > 0 && this.state.detail.places_lat_long.map((element, index) => (
                                                    <AnyReactComponent
                                                        key={index}
                                                        lat={element.lat}
                                                        lng={element.lng}
                                                        text="Marker"
                                                    />
                                                ))}

                                            </GoogleMapReact>
                                        </div>
                                    }
                                </div> */}
                            </div>

                            <div>
                                <Modal isOpen={this.state.modal} className="custom_dealmodel">

                                    {/* <div className="closepopup_rel"><ModalHeader close={closeModel}></ModalHeader></div> */}
                                    <ModalBody className="custom_dealBody">

                                        <div className="open_deal" style={{ marginTop: "25%" }}>

                                            <div className="opendealInner">
                                                <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.closeToggle(this, 'modal') }} alt="" title="" className="dealMdlClose" />
                                                <div className="open_deal_content">
                                                    <h4>Type of Deal</h4>
                                                    <p>
                                                        {dynamic_content.static_text.perksdetails.tape_to_continue.en}
                                                    </p>
                                                </div>
                                                <Button style={this.state.detail && this.state?.detail?.is_upgraded ? { background: this.state.detail.detail_custom.button_color } : {}} size="medium" color="primary" className="closeModel onlineModel" onClick={() => { this.clickOnline() }}>
                                                    {dynamic_content.static_text.perksdetails.online.en}
                                                </Button>
                                                <Button size="medium" color="primary" className="closeModel instore_btn" onClick={() => { this.clickInStore() }} >
                                                    {dynamic_content.static_text.perksdetails.instore.en}
                                                </Button>
                                            </div>
                                            {this.state.detail && this.state.detail.upgrade == 1 && this.state?.detail?.is_upgraded == 0 &&
                                                <div className="upgradJoin" style={{ background: this.state.detail_custom ? this.state.detail_custom.background : "" }}>
                                                    <h3 style={{ color: this.state.detail_custom ? this.state.detail_custom.text1_color : "" }}>Upgrade to Join</h3>
                                                    <h3 style={{ color: this.state.detail_custom ? this.state.detail_custom.text1_color : "" }}>Legoland Get an extra 20% off</h3>
                                                    <Button style={{ background: this.state.detail_custom ? this.state.detail_custom.button_color : "", color: this.state.detail_custom ? this.state.detail_custom.button_text_color : "" }} size="medium" color="primary" className="closeModel" onClick={() => { this.clickUpgrade() }}>
                                                        {dynamic_content.static_text.perksdetails.upgrade.en}
                                                    </Button>
                                                    <p style={{ color: this.state.detail_custom ? this.state.detail_custom.terms_color : "" }}>
                                                        {ReactHtmlParser(dynamic_content.static_text.perksdetails.enter_terms_policy.en)}
                                                    </p>
                                                </div>
                                            }
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                            {this.state.cookie_auto_id && this.state.cookie_auto_id != null &&
                                <Footer props={this.props} />
                            }
                            {this.state.show_t_and_c &&
                                <TermsCondition Back={this.handleCloseTerms.bind(this)} program={this.state.current_perk} />
                            }
                            {/* Type of deal model */}

                        </div>
                    }
                    </div>
                </div>
            </div>
        );
    }

}
export default Perksdetailall;

