import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import functions from '../../assets/functions/common_functions';
import cookie from 'react-cookies';
import url from '../../assets/url';
import { trackEvent } from '../../lib/facebookPixel';
var initials = require("initials");

export default class buildaddrealtive extends React.Component {

    constructor(props) {
        super(props);
        // let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : ""
        // if (auto_id && auto_id != "") {
        //     if (this.props.history.action == "POP") {

        //         this.props.history.goForward()
        //     }
        // }
        // else {
        //     this.props.history.push("/home")
        // }
        this.state = {
            relation_status: '',
            session_type: localStorage.getItem("session_type") ? localStorage.getItem("session_type") : "",
            relations: localStorage.getItem("relation_data") ? JSON.parse(localStorage.getItem("relation_data")) : [],
            delete_confirm_modal: false,
            modal_message: "",
            bounce_rel_id: localStorage.getItem("bounce_rel_id") ? localStorage.getItem("bounce_rel_id") : "",
            action: "",
            dynamic_content: ""
        };
        this.toggle = this.toggle.bind(this);
    }

    componentDidMount = () => {
        let previous_page = localStorage.getItem('previous_state');
        let current_state = localStorage.getItem('current_state');
        if (current_state !== 'buildaddrelative') {
            functions.getLandingPage(this);
        }
        // if(buildyourfamily)
        // functions.getLandingPage(this);
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
            let modal_message = dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.want_to_delete_relation ? dynamic_content.static_text.buildaddrelative.want_to_delete_relation.en : "Do you want to delete this relation?"
            this.setState({ modal_message: modal_message })
        }

        
        if (this.state.bounce_rel_id != "") {
            var index = this.state.relations.findIndex(p => p.id == this.state.bounce_rel_id)
            localStorage.setItem("edit_index", index)
            localStorage.setItem("form_type", 'edit_relation')
            localStorage.removeItem("bounce_rel_id")
            functions.pushToRoute(this, "addrelative", 'buildaddrelative');
        }
        let action = "User landed on buildaddrelative page"
        let image = 'buildaddrelative_page_landing.png'
        functions.logUserActivity(action, image);
    }

    handleContinue = (path) => {
        let action = "User clicked on " + path + " on buildaddrelative page"
        let image = path + '_button_buildaddrelative_page.png'
        functions.logUserActivity(action, image);
        localStorage.setItem("form_type", "add_relation")
        if (path == 'main') {
            let element = document.getElementById("signup_add_relative")
            if (localStorage.getItem("session_type") && localStorage.getItem("session_type") == "signup") {
                element.click();
                trackEvent('Signup With Relative New App', {event_action: 'User Clicked on Add relative button'})
            }

            let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
            if (perks_or_main == "perks") {
                localStorage.setItem("detail_page", 'false')
                functions.pushToRoute(this, "perks", 'buildfamily')
            }
            else {
                let enter_relative_program = localStorage.getItem("enter_relative_program") && localStorage.getItem("enter_relative_program") == "true" ? true : false;
                if (enter_relative_program == true) {
                    localStorage.setItem("detail_page", 'true')
                } else {
                    localStorage.setItem("detail_page", 'false')
                }
                localStorage.removeItem("enter_relative_program")
                functions.pushToRoute(this, 'main', 'buildaddrelative')
            }
        } else {
            functions.pushToRoute(this, path, 'buildaddrelative')
        }

    }

    toggle() {
        this.setState({
            modal: !this.state.modal
        });
    }

    handleDeclineRelation = () => {
        let relations = this.state.relations
        let final_relations = []
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (sub_id && sub_id != "") {
            let delete_relation = relations[this.state.relation_index]
            let delete_final_json = {
                "action": "delete-relative-by-email",
                sub_id: sub_id,
                relative_email: delete_relation.email
            }
            functions.SyncInMysql(this, delete_final_json);
            relations.forEach((element, index) => {
                if (index !== this.state.relation_index) {
                    final_relations.push(element)
                }
                else {
                    let self = this;

                    let user_email = localStorage.getItem('email');
                    functions.DeclineRelation(this, sub_id, element.relative_id, user_email, function (response_json) {

                    });
                }
            });

            this.setState({ relations: final_relations, open_edit: false })
            this.toggleDeleteConfirm()
            //api to delete relation 
            if (final_relations.length < 1) {
                localStorage.removeItem("relation_data")
                functions.pushToRoute(this, "buildfamily", 'buildaddrelative')
            } else {
                localStorage.setItem("relation_data", JSON.stringify(final_relations))
            }
        }
    }

    handleAcceptRelation = () => {
        let action = "User clicked on accept relation button on buildaddrelative page"
        let image = 'accept_relation_button_buildaddrelative_page.png'
        functions.logUserActivity(action, image);
        let relations = this.state.relations
        let final_relations = []
        relations.forEach((element, index) => {
            if (index == this.state.relation_index) {
                element.relation_status = 'approved_relations';
                let self = this;
                let sub_id = cookie.load('auto_id');
                let user_email = localStorage.getItem('email');
                functions.AcceptRelation(self, sub_id, element.relative_id, user_email, function (response_json) {

                });
            }
            final_relations.push(element)

        });
        this.toggle()
        this.setState({ relations: final_relations })

        localStorage.setItem("relation_data", JSON.stringify(final_relations))
    }

    handleRelationIndex = (index, relation) => {
        let all_relation = this.state.relations
        let edit_relation = all_relation[index];
        let action = "User opened edit relation popup on buildaddrelative page"
        let image = 'edit_relation_popup_buildaddrelative_page.png'
        functions.logUserActivity(action, image);
        
        this.setState({ relation_index: index, relation_status: edit_relation.relation_status });
        this.toggle()
    }

    handleDelete = () => {
        
        this.toggleDeleteConfirm()
        let relations = this.state.relations
        let final_relations = []
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (sub_id && sub_id != "") {
            relations.forEach((element, index) => {
                if (index !== this.state.relation_index) {
                    final_relations.push(element)
                }
                else {
                    let user_email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email");
                    functions.deleteRelation(this, element.email, user_email, element.relative_id);
                }
            });
            this.setState({ relations: final_relations, open_edit: false })

            //api to delete relation 
            // this.handleUpdate(final_relations);
            if (final_relations.length < 1) {
                localStorage.removeItem("relation_data")
                let enter_relative_program = localStorage.getItem("enter_relative_program") && localStorage.getItem("enter_relative_program") == "true" ? true : false;
                if (enter_relative_program == true) {
                    this.handleContinue('main')
                }
                else {
                    functions.pushToRoute(this, 'existingRelativeList', 'buildaddrelative');
                }
                // this.handleAddChild("Expecting")
            } else {
                localStorage.setItem("relation_data", JSON.stringify(final_relations))
            }
        }
    }

    handleEdit = () => {
        let action = "User clicked on edit on buildaddrelative page"
        let image = 'edit_button_buildaddrelative_page.png';
        let relations = this.state.relations
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        if (sub_id && sub_id != "") {
            let relation_email = relations[this.state.relation_index].email
            let status = relations[this.state.relation_index].relation_status;
            if (status === 'pending_relation') {
                let self = this;
                functions.CheckRelationStatus(this, relation_email, sub_id, function (json_response) {
                    let data = JSON.parse(json_response).data;
                    if (data?.relationAccepted) {
                        functions.logUserActivity(action, image);
                        localStorage.setItem("edit_index", self.state.relation_index)
                        localStorage.setItem("form_type", 'edit_relation')
                        functions.pushToRoute(self, "addrelative", 'buildaddrelative');
                        self.toggle()
                    }
                    else {
                        self.setState({ modal_message: "Relative already accepted your invitation, edit is not available for this relative" })
                        self.toggle();
                        self.toggleEditRelationConfirm();
                    }
                })
            }
            else {
                functions.logUserActivity(action, image);
                localStorage.setItem("edit_index", this.state.relation_index)
                localStorage.setItem("form_type", 'edit_relation')
                functions.pushToRoute(this, "addrelative", 'buildaddrelative');
                this.toggle()
            }

        }

    }

    closeRelationCancel = () => {
        let relations = this.state.relations
        let relation_index = this.state.relation_index;
        relations[relation_index]['relation_status'] = "approved_relations";
        this.setState({ relations })
        if (relations.length) localStorage.setItem("relation_data", JSON.stringify(relations))
        this.componentDidMount();
    }

    toggleEditRelationConfirm = () => {
        this.setState({
            relation_edit_cancel: !this.state.relation_edit_cancel
        });
    }

    closeToggle(value, state) {
        let action = "User closed edit popup on buildaddrelative page"
        let image = 'close_button_edit_popup_buildaddrelative_page.png'
        functions.logUserActivity(action, image);
        value.setState({
            [state]: !value.state[state]
        });
    }

    toggleDeleteConfirm() {
        let { delete_confirm_modal } = this.state;
        if (delete_confirm_modal == false) {
            this.toggle()
            let action = "User open delete confirmation popup on buildaddrelative page"
            let image = 'delete_confirmation_popup_buildaddrelative_page.png'
            functions.logUserActivity(action, image);
        } else {
            let action = "User close delete confirmation popup on buildaddrelative page"
            let image = 'close_button_delete_confirmation_popup_buildaddrelative_page.png'
            functions.logUserActivity(action, image);
        }
        this.setState({
            delete_confirm_modal: !delete_confirm_modal
        });
    }

    handleYes = () => {
        let action1 = "User clicked on yes on delete confirmation popup on buildaddrelative page"
        let image = 'yes_button_delete_confirmation_popup_buildaddrelative_page.png'
        functions.logUserActivity(action1, image);
        let action = this.state.action
        if (action == "delete") {
            this.handleDelete()
        }
        else if (action == "decline") {
            this.handleDeclineRelation()
        }
    }

    handleClick = (value) => {
        let dynamic_content = this.state.dynamic_content
        let action = "User clicked on " + value + " on buildaddrelative page"
        let image = value + '_buildaddrelative_page.png'
        functions.logUserActivity(action, image);
        if (value == "delete") {
            this.setState({ action: value, modal_message: dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.want_to_delete_relation ? dynamic_content.static_text.buildaddrelative.want_to_delete_relation.en : "Do you want to delete this relation?" })
        }
        else if (value == "decline") {
            this.setState({ action: value, modal_message: dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.want_to_decline_relation ? dynamic_content.static_text.buildaddrelative.want_to_decline_relation.en : "Do you want to delete this relation?" })
        }
        this.toggleDeleteConfirm()
    }

    render() {
        const { dynamic_content, delete_confirm_modal } = this.state
        return (
            <div className="container">
                {dynamic_content != "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                        <div className="common_main resSection_main resMainSec">

                            <div className="signUpFxd">
                                <div className="sign_backlink sign_backlinkNw"><img onClick={() => {
                                    let action = "User click on back button on  buildaddrelative page"
                                    let image = 'back_button_buildaddrelative_page.png'
                                    functions.logUserActivity(action, image);
                                    let enter_relative_program = localStorage.getItem("enter_relative_program") && localStorage.getItem("enter_relative_program") == "true" ? true : false;
                                    
                                    if (enter_relative_program == true) {
                                        this.handleContinue('main')
                                    } else {
                                        functions.pushToRoute(this, 'buildyourfamily', 'buildaddrealtive')
                                    }

                                }} src={require('../../assets/images/bkArorIcon.svg')}  alt="" title="" /></div>
                                <div className="sign_up">
                                    <h1>{this.state.session_type == "signup" ? (dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.signup) ? dynamic_content.static_text.buildaddrelative.signup.en : "Sign Up" : ""}</h1>
                                </div>
                                <div className="point_benefits">
                                    <h2>{dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.build_your_family ? dynamic_content.static_text.buildaddrelative.build_your_family.en : "Build Your Family"}</h2>
                                    <p>{dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.points_and_benfit ? dynamic_content.static_text.buildaddrelative.points_and_benfit.en : "Text about points and benefits"} </p>
                                </div>
                            </div>

                            <div className="child_info_space"></div>
                            <div className="many_childs">

                                {this.state.relations && this.state.relations.length > 0 && this.state.relations.map((relation, index) => (
                                    <div key={index} className="child_info">
                                        <div className="child_left"><label>{initials((relation.given_name + " " + relation.family_name).toUpperCase()).substring(0, 2)}</label></div>
                                        <div className="child_rght">
                                            <label>{relation.given_name + " " + relation.family_name}</label>
                                            {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation == "In-law" &&
                                                <p>{relation.relation + " " + relation.optional_relation}</p>
                                            }
                                            {relation.optional_relation && relation.optional_relation != "" && relation.optional_relation != "In-law" &&
                                                <p>{relation.optional_relation + " " + relation.relation}</p>
                                            }
                                            {relation.optional_relation && relation.optional_relation == "" &&
                                                <p>{relation.relation}</p>
                                            }
                                            {!relation.optional_relation &&
                                                <p>{relation.relation}</p>
                                            }
                                            <a onClick={() => { this.handleRelationIndex(index, relation) }}>:</a>
                                        </div>
                                    </div>
                                ))}

                            </div>

                            <div className="child_count">
                                <div className="addbaby_submit">
                                    <Button size="medium" color="primary" onClick={() => { this.handleContinue('addrelative') }}>
                                        {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.add_relative ? dynamic_content.static_text.buildaddrelative.add_relative.en : "Add Relative"}
                                    </Button>
                                </div>
                                <div className="addbuild_submit">
                                    <Button size="medium" color="primary" onClick={() => { this.handleContinue('main') }}>
                                        {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.continue ? dynamic_content.static_text.buildaddrelative.continue.en : "Continue"}
                                    </Button>
                                    <div id="signup_add_relative"></div>
                                </div>
                            </div>

                            {/* Add another child model */}
                            <div className="open_mail_model">
                                <Modal isOpen={this.state.modal} toggle={this.toggle} className={this.props.className + " custom_Addchildmodel"} >
                                    <ModalBody className="custom_editchildBody">
                                        {this.state.relation_status == "pending_invitation" &&
                                            <div className="editchild_content" style={{ postion: "relative" }}>
                                                <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.closeToggle(this, 'modal') }} className="clearcontest_img popup_close_button" />
                                                <Button style={{ marginTop: "20px" }} size="medium" color="primary" onClick={() => { this.handleAcceptRelation() }}>
                                                    {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.accept ? dynamic_content.static_text.buildaddrelative.accept.en : "Accept"}
                                                </Button>
                                                <Button size="medium" color="primary" className="closeModel childdel_btn" onClick={() => { this.handleClick('decline') }} >
                                                    {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.decline ? dynamic_content.static_text.buildaddrelative.decline.en : "Decline"}
                                                </Button>
                                            </div>
                                        }
                                        {this.state.relation_status != "pending_invitation" &&
                                            <div className="editchild_content" style={{ postion: "relative" }}>
                                                <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.closeToggle(this, 'modal') }} className="clearcontest_img popup_close_button" />
                                                {this.state.relation_status != 'approved_relations' &&
                                                    <Button style={{ marginTop: "20px" }} size="medium" color="primary" onClick={() => { this.handleEdit() }}>
                                                        {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.edit ? dynamic_content.static_text.buildaddrelative.edit.en : "Edit"}
                                                    </Button>
                                                }
                                                <Button size="medium" color="primary" className="deleteButton closeModel childdel_btn" onClick={() => { this.handleClick('delete') }} >
                                                    {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.delete ? dynamic_content.static_text.buildaddrelative.delete.en : "Delete"}
                                                </Button>
                                            </div>
                                        }
                                    </ModalBody>
                                </Modal>
                            </div>
                            <div className="open_mail_model">
                                <Modal isOpen={delete_confirm_modal} toggle={this.toggleDeleteConfirm} className={this.props.className+ " skilltestmodel"} >
                                    <ModalHeader style={{ borderBottom: "none" }}>
                                        <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.toggleDeleteConfirm() }} alt="" title="" className="clearcontest_img confirm_close_button" />
                                    </ModalHeader>
                                    <ModalBody className="skilltestBody">
                                        <div className="support_model" style={{ postion: "relative" }}>
                                            <div className="skill_query">
                                                <p style={{ fontSize: "20px" }}>{this.state.modal_message}</p>
                                            </div>
                                            <div className="skilltest_content">
                                                <Button size="medium" color="primary" onClick={() => { this.handleYes() }}>
                                                    {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.yes ? dynamic_content.static_text.buildaddrelative.yes.en : "Yes"}
                                                </Button>
                                                <Button size="medium" color="primary" onClick={() => { this.toggleDeleteConfirm() }}>
                                                    {dynamic_content.static_text.buildaddrelative && dynamic_content.static_text.buildaddrelative.no ? dynamic_content.static_text.buildaddrelative.no.en : "No"}
                                                </Button>
                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                            </div>
                            <div className="open_mail_model">
                                <Modal onClosed={this.closeRelationCancel} isOpen={this.state.relation_edit_cancel} toggle={this.toggleEditRelationConfirm} className={this.props.className + " skilltestmodel"}>
                                    <ModalHeader style={{ borderBottom: "none" }}>
                                        <img src={require('../../assets/images/closeChild.svg')} onClick={() => { this.closeToggle(this, 'relation_edit_cancel') }} alt="" title="" className="clearcontest_img confirm_close_button" />
                                    </ModalHeader>
                                    <ModalBody className="skilltestBody">
                                        <div className="support_model" style={{ postion: "relative" }}>
                                            <div className="skill_query">
                                                <p style={{ fontSize: "20px" }}>{this.state.modal_message}</p>
                                            </div>
                                            <div className="cancel_edit">
                                                <Button size="medium" color="primary" onClick={() => { this.closeToggle(this, 'relation_edit_cancel') }}>
                                                    Ok
                                            </Button>

                                            </div>
                                        </div>
                                    </ModalBody>
                                </Modal>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}