var address = {
    getAddress(store, SearchTerm, country, callback) {

        var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3ex.ws';
        // var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/wsdlnew.ws';
        var params = '';
        params += "&Key=" + encodeURIComponent('MM44-YZ97-ME83-UC98');
        params += "&SearchTerm=" + encodeURIComponent(SearchTerm);
        params += "&Country=" + encodeURIComponent(country);
        params += "&LanguagePreference=" + encodeURIComponent('en');
        params += "&LastId=" + encodeURIComponent('');
        params += "&SearchFor=" + encodeURIComponent('Places');
        params += "&OrderBy=" + encodeURIComponent('UserLocation');
        params += "&block=" + encodeURIComponent('true');
        params += "&cache=" + encodeURIComponent('true');

        const http = new XMLHttpRequest();
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.setRequestHeader('Access-Control-Allow-Origin', '*');
        http.send(params);
        http.onreadystatechange = (e) => {
            if (http.readyState == 4 && http.status == 200) {

                var response = JSON.parse(http.responseText);

                // Test for an error
                if (response.Items.length == 1 && typeof (response.Items[0].Error) != "undefined" && response.Items[0].Error != "1001") {
                    // Show the error message
                    alert(response.Items[0].Description);
                }
                else {
                    // Check if there were any items found
                    if (response.Items.length == 0) {
                        let result = {
                            error: 1,
                            message: "Sorry, there were no results"
                        }
                        callback(result);
                    }
                    else {
                        let address = [];
                        response.Items.forEach((element, index) => {
                            // if(index < 10){

                            if (element.Next == "Find") {
                                var arr = element.Description.split("-");
                                element.Description = arr[0]
                                if (element.Description.split(',').length == 3) {
                                    if(element.Description.split(',')[2].trim().length == 7){
                                        address.push(element);
                                    }
                                }
                            }
                            if (element.Next == "Retrieve") {
                                address.push(element);
                            }
                            if (index == (response.Items.length - 1)) {
                                callback(address);
                            }
                        });
                    }
                }
            }
        }
    },

    findAddress(store, SearchTerm, Id, country, callback) {

        var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/json3ex.ws';
        // var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/wsdlnew.ws';
        var params = '';
        params += "&Key=" + encodeURIComponent('MM44-YZ97-ME83-UC98');
        params += "&SearchTerm=" + encodeURIComponent(SearchTerm);
        params += "&Country=" + encodeURIComponent(country);
        params += "&LanguagePreference=" + encodeURIComponent('en');
        params += "&LastId=" + encodeURIComponent(Id);
        params += "&SearchFor=" + encodeURIComponent('Everything');
        params += "&OrderBy=" + encodeURIComponent('UserLocation');
        params += "&block=" + encodeURIComponent('true');
        params += "&cache=" + encodeURIComponent('true');

        const http = new XMLHttpRequest();
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.setRequestHeader('Access-Control-Allow-Origin', '*');
        http.send(params);
        http.onreadystatechange = (e) => {
            if (http.readyState == 4 && http.status == 200) {

                var response = JSON.parse(http.responseText);
                // Test for an error
                if (response.Items.length == 1 && typeof (response.Items[0].Error) != "undefined") {
                    // Show the error message
                    alert(response.Items[0].Description);
                }
                else {
                    // Check if there were any items found
                    if (response.Items.length == 0) {
                        let result = {
                            error: 1,
                            message: "Sorry, there were no results"
                        }
                        callback(result);
                    }
                    else {
                        let address = [];
                        response.Items.forEach((element, index) => {
                            // if(index < 10){
                            address.push(element);
                            if (index == (response.Items.length - 1)) {
                                callback(address);
                            }
                            // }

                        });
                    }
                }
            }
        }
    },

    findAddressById(store, SearchTerm, Id, country, callback) {

        var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/RetrieveFormatted/v2.10/json3ex.ws';
        // var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/wsdlnew.ws';
        var params = '';
        params += "&Key=" + encodeURIComponent('MM44-YZ97-ME83-UC98');
        params += "&Id=" + encodeURIComponent(Id);

        const http = new XMLHttpRequest();
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.setRequestHeader('Access-Control-Allow-Origin', '*');
        http.send(params);
        http.onreadystatechange = (e) => {
            if (http.readyState == 4 && http.status == 200) {

                var response = JSON.parse(http.responseText);
                // Test for an error
                if (response.Items.length == 1 && typeof (response.Items[0].Error) != "undefined") {
                    // Show the error message
                    alert(response.Items[0].Description);
                }
                else {
                    // Check if there were any items found
                    if (response.Items.length == 0) {
                        let result = {
                            error: 1,
                            message: "Sorry, there were no results"
                        }
                        callback(result);
                    }
                    else {
                        let address = [];
                        response.Items.forEach((element, index) => {
                            // if(index < 10){
                            address.push(element);
                            if (index == (response.Items.length - 1)) {
                                callback(address);
                            }
                            // }

                        });
                    }
                }
            }
        }
    },

    findFullAddressById(store, Id, callback) {

        var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Retrieve/v2.11/json3.ws';
        // var url = 'https://ws1.postescanada-canadapost.ca/AddressComplete/Interactive/Find/v2.10/wsdlnew.ws';
        var params = '';
        params += "&Key=" + encodeURIComponent('MM44-YZ97-ME83-UC98');
        params += "&Id=" + encodeURIComponent(Id);

        const http = new XMLHttpRequest();
        http.open('POST', url, true);
        http.setRequestHeader('Content-type', 'application/x-www-form-urlencoded');
        http.setRequestHeader('Access-Control-Allow-Origin', '*');
        http.send(params);
        http.onreadystatechange = (e) => {
            if (http.readyState == 4 && http.status == 200) {

                var response = JSON.parse(http.responseText);
                // Test for an error
                if (response.Items.length == 1 && typeof (response.Items[0].Error) != "undefined") {
                    // Show the error message
                    alert(response.Items[0].Description);
                }
                else {
                    // Check if there were any items found
                    if (response.Items.length == 0) {
                        let result = {
                            error: 1,
                            message: "Sorry, there were no results"
                        }
                        callback(result);
                    }
                    else {
                        let address = [];
                        response.Items.forEach((element, index) => {
                            // if(index < 10){
                            address.push(element);
                            if (index == (response.Items.length - 1)) {
                                callback(address);
                            }
                            // }

                        });
                    }
                }
            }
        }
    },
};
export default address;
