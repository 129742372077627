import React from 'react';
import '../../assets/css/style.css';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { TimelineLite, TweenLite, TweenMax, Expo, TimelineMax, Bounce, Circ, Linear, Power1, Power2, Power3, Back, Power4, SlowMo } from "gsap/all";
import classnames from 'classnames';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import LottieLoader from '../LottieLoader';
import Footer from '../innerpages/footer';
import PerksDetail from './perksdetails';
import Programtile from '../home/program_tiles';
import $ from "jquery";
import cookie from 'react-cookies';
import CSSPlugin from 'gsap/CSSPlugin';
import Geocode from "react-geocode";
const C = CSSPlugin;
const tab_names = [
    'All',
    'Beauty',
    'Entertainment',
    'Family Fun',
    'Fashion',
    'Food',
    'Lifestyle',
    'Sports',
    'Travel'
]
class perks extends React.Component {
    constructor(props) {
        super(props);
        this.toggleTab = this.toggleTab.bind(this);
        this.state = {
            active: false,
            card_color: "",
            detail: {},
            activeTab: '1',
            tab_names: tab_names,
            All: [],
            Beauty: [],
            Entertainment: [],
            "Family Fun": [],
            dynamic_content: "",
            Fashion: [],
            Food: [],
            Lifestyle: [],
            auto_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id"),
            Sports: [],
            Travel: [],
            new_tabs: [],
            main_loader: false,
            show_detail: false
        };
    }

    toggleTab(tab) {
        let action
        let image
        if (tab == "1") {
            action = "User clicked on All button on perk page"
            image = 'all_button_perk_page.png'
        }
        if (tab == "2") {
            action = "User clicked on Fashion button on perk page"
            image = 'fashion_button_perk_page.png'
        }
        if (tab == "3") {
            action = "User clicked on Travel button on perk page"
            image = 'travel_button_perk_page.png'
        }
        if (tab == "4") {
            action = "User clicked on Beauty button on perk page"
            image = 'beauty_button_perk_page.png'
        }
        if (tab == "5") {
            action = "User clicked on Sports button on perk page"
            image = 'sports_button_perk_page.png'
        }
        if (tab == "6") {
            action = "User clicked on Entertainment button on perk page"
            image = 'entertainment_button_perk_page.png'
        }
        if (tab == "7") {
            action = "User clicked on Food button on perk page"
            image = 'food_button_perk_page.png'
        }
        if (tab == "8") {
            action = "User clicked on Lifestyle button on perk page"
            image = 'lifestyle_button_perk_page.png'
        }
        if (tab == "9") {
            action = "User clicked on Family Fun button on perk page"
            image = 'familyfun_button_perk_page.png'
        }
        functions.logUserActivity(action, image);
        if (this.state.activeTab !== tab) {
            this.setState({
                activeTab: tab
            });
        }
    }

    componentDidMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let action = "User landed on perk page"
        let image = 'perk_page_landing.png'
        functions.logUserActivity(action, image);
        let session_entered_id = localStorage.getItem("session_entered_id") ? localStorage.getItem("session_entered_id") : "";
        localStorage.setItem("perk_source", "perks")
        localStorage.setItem("active_button", "1")
        window.scrollTo(0, 1);
        let self = this;
        let tabs = [];
        if (localStorage.getItem("CheckMainIndex") == "perks") {
            // this scope is used to prevent reload of page when user click on back button
            localStorage.removeItem('CheckMainIndex');
            let perks = JSON.parse(localStorage.getItem("perk_response"));
            perks = perks.sort(function (a, b) {
                return a.order_number - b.order_number
            })
            let perk_data = perks.filter((e) => e.is_visible != 0);
            if (perk_data && perk_data.length > 0) {
                perk_data.forEach((perk_detail, perk_index) => {
                    // let places_lat_long = []
                    // Geocode.setApiKey("AIzaSyA10e_yHodj3O1MbN_bOBCPDnnP6GPCeN8")
                    // if (perk_detail.perk_location && perk_detail.perk_location.length > 0) {
                    //     perk_detail.perk_location.forEach((element, index) => {

                    //         if (element && element != "Address need to update" && element != "Address need to be update") {
                    //             Geocode.fromAddress(element).then(
                    //                 response => {

                    //                     const { lat, lng } = response.results[0].geometry.location;
                    //                     places_lat_long.push({ lat: lat, lng: lng })

                    //                     if (index == (perk_detail.perk_location.length - 1)) {
                    //                         perk_data[perk_index].places_lat_long = places_lat_long;
                    //                     }
                    //                 },
                    //                 error => {
                    //                     console.error(error);
                    //                 }
                    //             );
                    //         }

                    //     })
                    // }
                })
            }
            let Beauty = [],
                Entertainment = [],
                FamilyFun = [],
                Fashion = [],
                Food = [],
                Lifestyle = [],
                Sports = [],
                Travel = [],
                Health = [],
                Auto = [],
                Insurance = []

            self.setState({ main_loader: true })
            perk_data.forEach((element, index) => {
                if (element.active_status === "active") {
                    let temp = {
                        category: element.category,
                        menu_tab_background: element.menu_tab_background,
                        menu_tab_text_color: element.menu_tab_text_color,
                        active_menu_tab_background: element.active_menu_tab_background,
                        active_menu_tab_text_color: element.active_menu_tab_text_color
                    }
                    tabs.push(temp)
                    switch (element.category) {
                        case "Fashion":
                            element.perk_index = index + 1
                            Fashion.push(element)
                            break;
                        case "Entertainment":
                            element.perk_index = index + 1
                            Entertainment.push(element)
                            break;
                        case "Travel":
                            element.perk_index = index + 1
                            Travel.push(element)
                            break;
                        case "Beauty":
                            element.perk_index = index + 1
                            Beauty.push(element)
                            break;
                        case "Sports":
                            element.perk_index = index + 1
                            Sports.push(element)
                            break;
                        case "Food":
                            element.perk_index = index + 1
                            Food.push(element)
                            break;
                        case "Lifestyle":
                            element.perk_index = index + 1
                            Lifestyle.push(element)
                            break;
                        case "Family Fun":
                            element.perk_index = index + 1
                            FamilyFun.push(element)
                            break;
                        case "Health":
                            element.perk_index = index + 1
                            Health.push(element)
                            break;
                        case "Auto":
                            element.perk_index = index + 1
                            Auto.push(element)
                            break;
                        case "Insurance":
                            element.perk_index = index + 1
                            Insurance.push(element)
                            break;
                    }
                }
            })

            var flags = [], output = [], new_tabs = [], l = tabs.length, i;
            output.push({
                category: "All",
                menu_tab_background: "#FFFFFF",
                menu_tab_text_color: "#739e80",
                active_menu_tab_background: "#739e80",
                active_menu_tab_text_color: "#FFFFFF",
            })
            for (i = 0; i < l; i++) {
                if (flags[tabs[i].category]) continue;
                flags[tabs[i].category] = true;
                output.push(tabs[i]);
                new_tabs.push(tabs[i]);
            }
            self.setState({
                tab_names: output,
                All: perk_data,
                Beauty: Beauty,
                Entertainment: Entertainment,
                "Family Fun": FamilyFun,
                Fashion: Fashion,
                Food: Food,
                Lifestyle: Lifestyle,
                Sports: Sports,
                Travel: Travel,
                new_tabs: new_tabs,
                main_loader: false,
                Health: Health,
                Auto: Auto,
                Insurance: Insurance
            })
            self.toggleAnimation();
            setTimeout(() => {
                functions.scrollToDiv(localStorage.getItem("program_div"))
            }, 10);

            if (session_entered_id != "") {
                var index = perk_data.findIndex(p => p.auto_id == session_entered_id)
                let temp_perk = perk_data[index]
                if (temp_perk) {
                    localStorage.setItem("perks_detail", JSON.stringify(temp_perk))
                    let click_id = "perks_" + temp_perk.auto_id

                    if (click_id && click_id != null) {
                        document.getElementById(click_id).click();
                    }
                    self.setState({ show_detail: true, detail: temp_perk })
                    localStorage.removeItem("perks_or_main")
                    localStorage.removeItem("session_entered_id")
                }
                self.setState({ main_loader: false })
            }
            else {
                self.setState({ main_loader: false })
            }
            return;
        }
        self.setState({ main_loader: true })
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        sub_id = sub_id ? sub_id : ""
        let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
        email = email ? email : ""
        let address = localStorage.getItem("address") ? JSON.parse(localStorage.getItem("address")) : {}
        let province = address.province ? address.province : ""
        if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
            let finaljson = {
                email: email,
                sub_id: sub_id
            }
            functions.checkUserExistence(self, finaljson, function (response1_json) {
                let response1 = JSON.parse(response1_json)

                if (response1.data && response1.data.dynamo && response1.data.dynamo.Items && response1.data.dynamo.Items.length == 0) {
                    self.setState({ main_loader: false })
                    functions.pushToRoute(self, "", "perks")
                } else {
                    functions.getPerks(this, province, function (response_json) {
                        let response = JSON.parse(response_json);
                        if (response.catch_error) {
                            self.setState({ main_loader: false })
                        }
                        else if (!response.catch_error) {
                            if (response && response.length > 0) {
                                let temp_perk_data = response.sort(function (a, b) {
                                    return a.order_number - b.order_number
                                })
                                localStorage.setItem("perk_response", JSON.stringify(temp_perk_data))
                                let perk_data = temp_perk_data.filter((e) => e.is_visible != 0);
                                if (perk_data && perk_data.length > 0) {
                                    perk_data.forEach((perk_detail, perk_index) => {
                                        // let places_lat_long = []
                                        // Geocode.setApiKey("AIzaSyA10e_yHodj3O1MbN_bOBCPDnnP6GPCeN8")
                                        // if (perk_detail.perk_location && perk_detail.perk_location.length > 0) {
                                        //     perk_detail.perk_location.forEach((element, index) => {
                                        //         if (element && element != "Address need to update" && element != "Address need to be update") {
                                        //             Geocode.fromAddress(element).then(
                                        //                 response => {
                                        //                     const { lat, lng } = response.results[0].geometry.location;
                                        //                     places_lat_long.push({ lat: lat, lng: lng })

                                        //                     if (index == (perk_detail.perk_location.length - 1)) {
                                        //                         perk_data[perk_index].places_lat_long = places_lat_long;
                                        //                     }
                                        //                 },
                                        //                 error => {
                                        //                     console.error(error);
                                        //                 }
                                        //             );
                                        //         }

                                        //     })
                                        // }
                                    })
                                }
                                let Beauty = [],
                                    Entertainment = [],
                                    FamilyFun = [],
                                    Fashion = [],
                                    Food = [],
                                    Lifestyle = [],
                                    Sports = [],
                                    Travel = [],
                                    Health = [],
                                    Auto = [],
                                    Insurance = []
                                perk_data.forEach((element, index) => {
                                    if (element.active_status === "active") {
                                        let temp = {
                                            category: element.category,
                                            menu_tab_background: element.menu_tab_background,
                                            menu_tab_text_color: element.menu_tab_text_color,
                                            active_menu_tab_background: element.active_menu_tab_background,
                                            active_menu_tab_text_color: element.active_menu_tab_text_color
                                        }
                                        tabs.push(temp)
                                        switch (element.category) {
                                            case "Fashion":
                                                element.perk_index = index + 1
                                                Fashion.push(element)
                                                break;
                                            case "Entertainment":
                                                element.perk_index = index + 1
                                                Entertainment.push(element)
                                                break;
                                            case "Travel":
                                                element.perk_index = index + 1
                                                Travel.push(element)
                                                break;
                                            case "Beauty":
                                                element.perk_index = index + 1
                                                Beauty.push(element)
                                                break;
                                            case "Sports":
                                                element.perk_index = index + 1
                                                Sports.push(element)
                                                break;
                                            case "Food":
                                                element.perk_index = index + 1
                                                Food.push(element)
                                                break;
                                            case "Lifestyle":
                                                element.perk_index = index + 1
                                                Lifestyle.push(element)
                                                break;
                                            case "Family Fun":
                                                element.perk_index = index + 1
                                                FamilyFun.push(element)
                                                break;
                                            case "Health":
                                                element.perk_index = index + 1
                                                Health.push(element)
                                                break;
                                            case "Auto":
                                                element.perk_index = index + 1
                                                Auto.push(element)
                                                break;
                                            case "Insurance":
                                                element.perk_index = index + 1
                                                Insurance.push(element)
                                                break;
                                        }
                                    }

                                })

                                var flags = [], output = [], new_tabs = [], l = tabs.length, i;
                                output.push({
                                    category: "All",
                                    menu_tab_background: "#FFFFFF",
                                    menu_tab_text_color: "#739e80",
                                    active_menu_tab_background: "#739e80",
                                    active_menu_tab_text_color: "#FFFFFF",
                                })
                                for (i = 0; i < l; i++) {
                                    if (flags[tabs[i].category]) continue;
                                    flags[tabs[i].category] = true;
                                    output.push(tabs[i]);
                                    new_tabs.push(tabs[i]);
                                }

                                self.setState({
                                    tab_names: output,
                                    All: perk_data,
                                    Beauty: Beauty,
                                    Entertainment: Entertainment,
                                    "Family Fun": FamilyFun,
                                    Fashion: Fashion,
                                    Food: Food,
                                    Lifestyle: Lifestyle,
                                    Sports: Sports,
                                    Travel: Travel,
                                    new_tabs: new_tabs,
                                    Health: Health,
                                    Insurance: Insurance,
                                    Auto: Auto,
                                    main_loader: false
                                })
                                // self.toggleAnimation();
                                if (session_entered_id != "") {
                                    // setTimeout(function () {
                                    var index = perk_data.findIndex(p => p.auto_id == session_entered_id)
                                    let temp_perk = perk_data[index]
                                    if (temp_perk) {
                                        localStorage.setItem("perks_detail", JSON.stringify(temp_perk))
                                        let click_id = "perks_" + temp_perk.auto_id
                                        if (click_id && click_id != null) {
                                            document.getElementById(click_id).click();
                                        }
                                        self.setState({ show_detail: true, detail: temp_perk })
                                        localStorage.removeItem("perks_or_main")
                                        localStorage.removeItem("session_entered_id")
                                    }
                                    self.setState({ main_loader: false })
                                    // }, 2000);
                                }
                                else {
                                    self.setState({ main_loader: false })
                                }
                                // self.handleFixedHeader()
                            }
                        }
                    })
                }
            })
        } else {
            functions.getPerks(this, province, function (response_json) {
                let response = JSON.parse(response_json);
                if (response.catch_error) {
                    self.setState({ main_loader: false })
                }
                else if (!response.catch_error) {
                    if (response && response.length > 0) {
                        let perk_data = response.sort(function (a, b) {
                            return a.order_number - b.order_number
                        })
                        if (perk_data && perk_data.length > 0) {
                            perk_data.forEach((perk_detail, perk_index) => {
                                // let places_lat_long = []
                                // Geocode.setApiKey("AIzaSyA10e_yHodj3O1MbN_bOBCPDnnP6GPCeN8")
                                // if (perk_detail.perk_location && perk_detail.perk_location.length > 0) {
                                //     perk_detail.perk_location.forEach((element, index) => {

                                //         if (element && element != "Address need to update" && element != "Address need to be update") {
                                //             Geocode.fromAddress(element).then(
                                //                 response => {

                                //                     const { lat, lng } = response.results[0].geometry.location;
                                //                     places_lat_long.push({ lat: lat, lng: lng })

                                //                     if (index == (perk_detail.perk_location.length - 1)) {
                                //                         perk_data[perk_index].places_lat_long = places_lat_long;
                                //                     }
                                //                 },
                                //                 error => {
                                //                     console.error(error);
                                //                 }
                                //             );
                                //         }

                                //     })
                                // }
                            })
                        }
                        let Beauty = [],
                            Entertainment = [],
                            FamilyFun = [],
                            Fashion = [],
                            Food = [],
                            Lifestyle = [],
                            Sports = [],
                            Travel = [],
                            Health = [],
                            Auto = [],
                            Insurance = []
                        perk_data.forEach((element, index) => {
                            if (element.active_status === "active") {
                                let temp = {
                                    category: element.category,
                                    menu_tab_background: element.menu_tab_background,
                                    menu_tab_text_color: element.menu_tab_text_color,
                                    active_menu_tab_background: element.active_menu_tab_background,
                                    active_menu_tab_text_color: element.active_menu_tab_text_color
                                }
                                tabs.push(temp)
                                switch (element.category) {
                                    case "Fashion":
                                        element.perk_index = index + 1
                                        Fashion.push(element)
                                        break;
                                    case "Entertainment":
                                        element.perk_index = index + 1
                                        Entertainment.push(element)
                                        break;
                                    case "Travel":
                                        element.perk_index = index + 1
                                        Travel.push(element)
                                        break;
                                    case "Beauty":
                                        element.perk_index = index + 1
                                        Beauty.push(element)
                                        break;
                                    case "Sports":
                                        element.perk_index = index + 1
                                        Sports.push(element)
                                        break;
                                    case "Food":
                                        element.perk_index = index + 1
                                        Food.push(element)
                                        break;
                                    case "Lifestyle":
                                        element.perk_index = index + 1
                                        Lifestyle.push(element)
                                        break;
                                    case "Family Fun":
                                        element.perk_index = index + 1
                                        FamilyFun.push(element)
                                        break;
                                    case "Health":
                                        element.perk_index = index + 1
                                        Health.push(element)
                                        break;
                                    case "Auto":
                                        element.perk_index = index + 1
                                        Auto.push(element)
                                        break;
                                    case "Insurance":
                                        element.perk_index = index + 1
                                        Insurance.push(element)
                                        break;
                                }
                            }
                        })

                        var flags = [], output = [], new_tabs = [], l = tabs.length, i;
                        output.push({
                            category: "All",
                            menu_tab_background: "#FFFFFF",
                            menu_tab_text_color: "#739e80",
                            active_menu_tab_background: "#739e80",
                            active_menu_tab_text_color: "#FFFFFF",
                        })
                        for (i = 0; i < l; i++) {
                            if (flags[tabs[i].category]) continue;
                            flags[tabs[i].category] = true;
                            output.push(tabs[i]);
                            new_tabs.push(tabs[i]);
                        }

                        self.setState({
                            tab_names: output,
                            All: perk_data,
                            Beauty: Beauty,
                            Entertainment: Entertainment,
                            "Family Fun": FamilyFun,
                            Fashion: Fashion,
                            Food: Food,
                            Lifestyle: Lifestyle,
                            Sports: Sports,
                            Travel: Travel,
                            new_tabs: new_tabs,
                            main_loader: false,
                            Health: Health,
                            Insurance: Insurance,
                            Auto: Auto
                        })
                        // self.toggleAnimation();
                        if (session_entered_id != "") {
                            // setTimeout(function () {
                            var index = perk_data.findIndex(p => p.auto_id == session_entered_id)
                            let temp_perk = perk_data[index]
                            if (temp_perk) {
                                localStorage.setItem("perks_detail", JSON.stringify(temp_perk))
                                let click_id = "perks_" + temp_perk.auto_id

                                if (click_id && click_id != null) {
                                    document.getElementById(click_id).click();
                                }
                                self.setState({ show_detail: true, detail: temp_perk })
                                localStorage.removeItem("perks_or_main")
                                localStorage.removeItem("session_entered_id")
                            }
                            self.setState({ main_loader: false })
                            // }, 2000);
                        }
                        else {
                            self.setState({ main_loader: false })
                        }
                        // self.handleFixedHeader()
                    }
                }
            })
        }
    }

    // handleFixedHeader = () => {
    //     document.addEventListener("scroll", function () {
    //         var navbar = document.getElementById("perks_nav_bar");
    //         if (navbar) {
    //             var sticky = navbar.offsetTop;
    //             if ($(window).scrollTop() > (sticky - 39)) {
    //                 $('.perks_navUpr').addClass('fixed-header');
    //             }
    //             else {
    //                 $('.perks_navUpr').removeClass('fixed-header');
    //             }
    //         }
    //     });
    // }

    handleUpdateUgrade = (perk_id, state) => {
        if (state == 'is_locked') {
            let perk_data = this.state.All
            var index = perk_data.findIndex(p => p.auto_id == perk_id)
            perk_data[index].locked = 0;
            let temp_perk = perk_data[index]
            let temp = this.state[temp_perk.category]
            temp.locked = 0;
            this.setState({ perk_data: perk_data })
            this.setState({ [temp_perk.category]: temp })
        } else if (state == 'is_upgraded') {
            let perk_data = this.state.All
            var index = perk_data.findIndex(p => p.auto_id == perk_id)
            perk_data[index].is_upgraded = 1;
            let temp_perk = perk_data[index]
            let temp = this.state[temp_perk.category]
            temp.is_upgraded = 1;
            this.setState({ perk_data: perk_data })
            this.setState({ [temp_perk.category]: temp })
        }
    }

    toggleAnimation = () => {
        let self = this;
        var root = document.documentElement;
        var body = document.body;
        var pages = document.querySelectorAll(".program-page");
        var tiles = document.querySelectorAll(".program-tile");

        for (var i = 0; i < tiles.length; i++) {
            addListeners(tiles[i], pages[i]);
        }

        function addListeners(program_tile, page) {
            program_tile.addEventListener("click", function () {
                animateHero(program_tile, page);
            });
            page && page !== undefined && page.querySelectorAll(".close_btn")[0].addEventListener("click", function () {
                animateHero(page, program_tile);
            });
        }

        function animateHero(fromHero, toHero) {
            var clone = fromHero.cloneNode(true);
            var from = calculatePosition(fromHero);
            var to = calculatePosition(toHero);
            TweenLite.set([fromHero, toHero], { visibility: "hidden", className: "-=active" });
            TweenLite.set(clone, { position: "absolute", margin: 0, background: "#F9EAE2" });
            body.appendChild(clone);
            var style = {
                x: to.left - from.left,
                y: to.top - from.top,
                width: to.width,
                height: to.height,
                autoRound: false,
                ease: Power1.easeOut,
                onComplete: onComplete
            };
            TweenLite.set(clone, from);
            TweenLite.to(clone, 0.3, style)

            if (clone.classList.contains('program-tile', 'program_detail_logo', 'program_title01')) {
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "16px" }, { bottom: "-31px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "20px" }, { 'font-size': "25px" }, 0)
                localStorage.setItem('detail_page', true);
            } else if (clone.classList.contains('program-page', 'program_detail_logo', 'freebies_iner')) {
                TweenMax.fromTo(clone.querySelectorAll(".program_detail_logo")[0], 0.3, { bottom: "-31px" }, { bottom: "16px" }, 0)
                TweenMax.fromTo(clone.querySelectorAll(".program_title01 label")[0], 0.3, { 'font-size': "25px" }, { 'font-size': "20px" }, 0)
                localStorage.setItem('detail_page', false);
            }

            function onComplete() {
                TweenLite.set(toHero, { visibility: "visible", className: "+=active" });
                if (body.contains(clone)) body.removeChild(clone);
            }
        }

        function calculatePosition(element) {
            var rect = element.getBoundingClientRect();
            var scrollTop = window.pageYOffset || root.scrollTop || body.scrollTop || 0;
            var scrollLeft = window.pageXOffset || root.scrollLeft || body.scrollLeft || 0;
            var clientTop = root.clientTop || body.clientTop || 0;
            var clientLeft = root.clientLeft || body.clientLeft || 0;
            return {
                top: Math.round(rect.top + scrollTop - clientTop),
                left: Math.round(rect.left + scrollLeft - clientLeft),
                height: rect.height,
                width: rect.width,
            };
        }
        /*End of Code of animation */
        // $('.program-page.active').find('.close_btn').trigger('click');
    }

    updatePerk = (detail, category, index) => {
        let perks = this.state[category]
        perks[index] = detail
        this.setState({ [category]: perks })
        localStorage.setItem("perks_detail", JSON.stringify(detail))
        // localStorage.setItem({})
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                    <div className="common_mainAddOn">
                        <div className="common_main">
                            {this.state.main_loader &&
                                <center className="lottieLoadCntr">
                                    <LottieLoader />
                                </center>
                            }
                            {!this.state.main_loader &&
                                <div className="">
                                    {/*Tabs content */}
                                    {!this.state.main_loader && this.state.dynamic_content != "" &&
                                        <div className="perks_tabs">
                                            <label className="perks_title">{dynamic_content.static_text.perks.perks.en}</label>
                                            <div id="perks_nav_bar" className="perks_navUpr">
                                                <Nav tabs className="perks_nav">
                                                    {this.state.tab_names && this.state.tab_names.length > 0 && this.state.tab_names.map((element, index) => (
                                                        <NavItem key={"nav" + index}>
                                                            <NavLink
                                                                className={classnames({ active: this.state.activeTab === (index + 1).toString() })}
                                                                onClick={() => { this.toggleTab((index + 1).toString()); }}
                                                                style={{
                                                                    background: this.state.activeTab === (index + 1).toString() ? element.active_menu_tab_background : element.menu_tab_background,
                                                                    color: this.state.activeTab === (index + 1).toString() ? element.active_menu_tab_text_color : element.menu_tab_text_color
                                                                }}
                                                            >
                                                                {element.category}
                                                            </NavLink>
                                                        </NavItem>
                                                    ))}
                                                </Nav>
                                            </div>
                                        </div>
                                    }
                                    {!this.state.main_loader && this.state.dynamic_content != "" &&
                                        <TabContent activeTab={this.state.activeTab} style={{ marginBottom: "20px" }}>
                                            {this.state.tab_names && this.state.tab_names.length > 0 && this.state.tab_names.map((element, index) => (
                                                <TabPane key={"main_category" + index} tabId={(index + 1).toString()}>
                                                    <div className="program-tile-container">
                                                        {this.state[element.category] && this.state[element.category].length > 0 && this.state[element.category].map((element1, index1) => (
                                                            <Programtile key={"perks_" + index1} props={this.props} program={element1} usedIn='perk' />
                                                        ))}
                                                    </div>

                                                    {/* <div className="program-page-container">
                                                    {this.state[element.category] && this.state[element.category].length > 0 && this.state[element.category].map((element1, index1) => (
                                                        <PerksDetail props={this.props} detail={element1} auto_id={this.state.auto_id} active={this.state.active} updateIsUpgrade={this.handleUpdateUgrade} />
                                                    ))}
                                                </div> */}
                                                </TabPane>
                                            ))}
                                        </TabContent>
                                    }
                                    <Footer props={this.props} />
                                    {/* End of Tabs content */}
                                </div>
                            }

                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
export default perks;

