import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import { Modal, ModalBody } from 'reactstrap';
import cookie from 'react-cookies';
import functions from '../../assets/functions/common_functions';
import $ from 'jquery';
import ReactHtmlParser, {
    // processNodes,
    // convertNodeToElement,
    // htmlparser2
} from "react-html-parser";
import url from '../../assets/url';
import LottieLoader from '../LottieLoader';
// import Address from './shipping_address';
import TermsCondition from '../TermsCondition/index'

const Month = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec"
};
class prize_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            first_answer: 0,
            second_answer: 0,
            third_answer: 0,
            loader: false,
            modal_two: false,
            modal_three: false,
            shipping_address: false,
            modal: false,
            program: "",
            dynamic_content: "",
            current_program: "",
            show_t_and_c: false,
            claim_status: cookie.load("claim_status") ? cookie.load("claim_status") : 'pending'
        };
        this.skilltest = this.skilltest.bind(this);
    }

    skilltest() {
        let success_action = "User clicked on claim prize button on prize detail page"
        let image = 'claim_prize_button_prize_detail_page.png'
        functions.logUserActivity(success_action, image);
        let skill_test = localStorage.getItem("skill_question") ? localStorage.getItem("skill_question") : "false";
        if (skill_test == "true") {
            functions.pushToRoute(this, 'shipping_address', 'prize_detail')
        }
        else {
            this.setState({
                modal: !this.state.modal
            });
        }
    }

    skilltest_one(value) {
        let success_action = "User clicked on " + value + " button on prize detail page"
        let image = 'skilltest1_button_prize_detail_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        this.setState({
            first_answer: value
        });
        if (value == '8') {
            setTimeout(function () {
                self.skilltest_two_open()
                self.setState({
                    modal: !self.state.modal
                });
            }, 500);
        }
    }

    skilltest_two(value) {
        let success_action = "User clicked on " + value + " button on prize detail page"
        let image = 'skilltest2_button_prize_detail_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        this.setState({
            second_answer: value
        });
        if (value == '8') {
            setTimeout(function () {
                self.skilltest_three_open()
                self.setState({
                    modal_two: !self.state.modal_two
                });
            }, 500);
        }
    }

    skilltest_two_open() {
        this.setState({
            modal_two: !this.state.modal_two
        });
    }

    skilltest_three_open() {
        this.setState({
            modal_three: !this.state.modal_three
        });
    }

    skilltest_three(value) {
        let success_action = "User clicked on " + value + " button on prize detail page"
        let image = 'skilltest3_button_prize_detail_page.png'
        functions.logUserActivity(success_action, image);
        let self = this
        this.setState({
            third_answer: value
        });
        if (value == '8') {
            setTimeout(function () {
                self.setState({
                    modal: false,
                    modal_two: false,
                    modal_three: false,
                    shipping_address: true
                    // claim_status: "claimed"
                })
                localStorage.setItem("skill_question", "true");

                let userdata = [];
                let dynamo_userdata = { 'skill_question': "true" };
                functions.updateUserData(self, userdata, dynamo_userdata);

                functions.pushToRoute(self, 'shipping_address', 'prize_detail')
            }, 500);
        }
    }

    componentDidMount = () => {
        let success_action = "User landed on prize detail page"
        let image = 'prize_detail_page_landing.png'
        functions.logUserActivity(success_action, image);
        this.handleTermsClick()
    }

    handleTermsClick = () => {
        let self = this;
        $(document).on('click', '.terms_span', function () {
            let program = self.state.program_array[0]
            self.handleTerms(program)
        })
    }

    handleTerms = (program) => {
        this.setState({ current_program: program, show_t_and_c: true });
    }

    handleCloseTerms = () => {
        let success_action = "User clicked on back button on terms and condition page"
        let image = 'back_button_terms_and_condition_page.png'
        functions.logUserActivity(success_action, image);
        this.setState({ show_t_and_c: false })
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let claim_days = cookie.load("winner_claim_days") ? cookie.load("winner_claim_days") : 0;
        // { "Claim prize / " + claim_days + " days left" }
        let claim_pending = dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.claim_days_left.en : ""
        let new_claim_pending;
        if (claim_pending !== "") {
            new_claim_pending = claim_pending.replace("%VALUE%", claim_days)
        }
        else {
            new_claim_pending = "Claim prize / " + claim_days + " days left"
        }
        dynamic_content.static_text.prize_detail.claim_days_left.en = new_claim_pending

        let auto_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
        auto_id = auto_id ? auto_id : ""
        if (auto_id !== "") {
            let self = this;
            // Set winner related info
            let winner_program_id = cookie.load("winner_program_id");
            let winner_id = cookie.load("winner_id");
            self.setState({ loader: true })
            if (winner_program_id && winner_id) {
                functions.getWinnerProgramDetail(this, winner_program_id, winner_id, function (response_json) {
                    let response = JSON.parse(response_json);
                    if (response.catch_error) {
                        self.setState({ loader: false })
                        let user_action = "User got error on innerpages/prize_detail file componentWillMount function";
                        let body = response.message
                        functions.logUserError(user_action, body);
                        self.setState({ show_error_message: response.message });
                    }
                    else if (!response.catch_error) {
                        if (response.data.error == 1) {
                            self.setState({ loader: false })
                            let user_action = "User got error on innerpages/prize_detail file componentWillMount function";
                            let body = response.data.message
                            functions.logUserError(user_action, body);
                            self.setState({ show_error_message: response.data.message })
                        } else {
                            let success_action = "User get success response on getWinnerProgramDetail function on prize detail page"
                            functions.logUserActivity(success_action, null);
                            self.setState({ loader: false })
                            let program = response.data.program_detail
                            if (program.short_description) {
                                let terms = program.short_description
                                let new_terms = terms.replace("Terms and Conditions", "<span className='terms_span'>Terms and Conditions</span>");
                                program.short_description = new_terms
                            }
                            let program_array = []
                            program_array.push(program)
                            self.setState({ program_array: program_array })
                        }
                    }
                })
            }
        }
        else {
            functions.pushToRoute(this, '', 'prize_detail')
        }
        // Set program related info

    }

    dateFormat = (value) => {
        let date_array = value.split("-");
        let year = date_array[0].substring(2, 4);
        let date = date_array[2];
        let month = date_array[1];
        let month_name = Month[month];
        let final_date = date + " " + month_name + " " + year;
        return final_date;
    }

    handleReview = () => {
        let success_action = "User clicked on review your prize button on prize detail page"
        let image = 'review_prize_button_prize_detail_page.png'
        functions.logUserActivity(success_action, image);
        let program_list = this.state.program_array
        if (program_list.length > 0) {
            let temp_program = program_list[0]
            localStorage.setItem("program", JSON.stringify(temp_program))
            localStorage.setItem("program_index", 0)
            localStorage.setItem("program_id", program_list[0].program_id)
            localStorage.setItem("winner_review", "true")
            functions.pushToRoute(this, 'uploadimg', 'prize_detail')
        }
    }

    handleBackArrow = () => {
        let success_action = "User clicked on back button on prize detail page"
        let image = 'back_button_prize_detail_page.png'
        functions.logUserActivity(success_action, image);
        cookie.save("winner_later", 'true', { path: '/' });
        let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
        if (previous_state == "profile") {
            functions.pushToRoute(this, "profile", 'prize_detail');
        }
        else {
            functions.pushToRoute(this, 'main', 'prize_detail')
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                {dynamic_content !== "" &&
                    <div className="row">
                        <div className="common_mainAddOn">
                        <div className="common_main detailticket_rmv">
                            {this.state.loader &&
                                <center>
                                    <LottieLoader />
                                </center>
                            }
                            {!this.state.loader && this.state.program_array && this.state.program_array.length > 0 && this.state.program_array.map((program, index) => (
                                <div key={index} className="ticket_baby">
                                    <div className="ticket_babyImg">

                                        <div className="detailback_section white_circle_div">
                                            <div>
                                                <div className="backLinkWidth">
                                                    <img
                                                        onClick={() => {
                                                            this.handleBackArrow()
                                                        }}
                                                        src={url.family_one_image_url + 'Group147.png'} alt="" title=""
                                                    />
                                                </div>
                                            </div>
                                            <div className="helpdiv"><a>{dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.help.en : "Help"}</a></div>
                                        </div>

                                        <div className="contests_dtlImges">
                                            <img src={url.cloudflare_image_path + program.program_image+url.varient} alt="" title="" />
                                        </div>

                                        <div className="program_detail_logo">
                                            {program.logo && program.logo.map((logo1) => (
                                                <a key={logo1}><img src={url.cloudflare_image_path + logo1+url.varient} alt="" title="" /></a>
                                            ))}
                                        </div>

                                    </div>

                                    <div className="details_ticket">

                                        <div className="ticket_details">
                                            <a><img style={{ width: "18px" }} src={url.family_one_image_url + 'Group34.1.png'} alt="" title="" />
                                                <span>Winner</span></a>
                                        </div>

                                        <div className="ticket_iner">
                                            <label>{ReactHtmlParser(program.program_title)}</label>
                                            <p>{ReactHtmlParser(program.program_subtitle)}</p>
                                        </div>

                                        <div className="descp_ticket">
                                            <label>Description</label>
                                            {ReactHtmlParser(program.program_description)}
                                        </div>

                                        <div className="ticket_redem">
                                            {this.state.claim_status == 'pending' &&
                                                <Button size="medium" color="primary" onClick={this.skilltest}>
                                                    {dynamic_content.static_text.prize_detail.claim_days_left.en}
                                                </Button>
                                            }
                                            {this.state.claim_status == 'claimed' &&
                                                <p className="claimed_txt">
                                                    {dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.claimed.en : "Claimed"}
                                                </p>
                                            }
                                        </div>

                                        <div className="ticket_redem1">
                                            {this.state.claim_status == 'claimed' &&
                                                <Button size="medium" color="primary" onClick={() => { this.handleReview() }}>
                                                    {dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.review_your_prize.en : "Review Your Prize"}
                                                </Button>
                                            }
                                        </div>

                                        <div className="declaration_ticket">
                                            <p>
                                                {ReactHtmlParser(program.short_description)}
                                            </p>
                                        </div>

                                    </div>
                                    {/* End of  three in One section */}
                                    {this.state.show_t_and_c &&
                                        <TermsCondition Back={this.handleCloseTerms.bind(this)} program={this.state.current_program} />
                                    }
                                    {/* Skill test model */}
                                    <div>
                                        <Modal isOpen={this.state.modal} toggle={this.skilltest} className="skilltestmodel">
                                            <ModalBody className="skilltestBody">
                                                <div className="support_model">
                                                    <div className="skilltest_label">
                                                        <label>{dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.answer_skill_test.en : "Answer the skill test to claim your prize"}</label>
                                                    </div>
                                                    <div className="skill_query">
                                                        <p>(1+1)</p><span>*(2+2)=</span>
                                                    </div>
                                                    <div className="skilltest_content">
                                                        {this.state.first_answer == '0' &&
                                                            <Button size="medium" color="primary"
                                                                onClick={() => this.skilltest_one('4')}
                                                            >4
                                                        </Button>
                                                        }
                                                        {this.state.first_answer == '8' &&
                                                            <Button size="medium" color="primary"
                                                                onClick={() => this.skilltest_one('4')}
                                                            >4
                                                        </Button>
                                                        }
                                                        {this.state.first_answer == '4' &&
                                                            <Button size="medium" color="primary" className="rightwrong">
                                                                <img style={{ width: "11px" }} src={url.family_one_image_url + 'Group202.png'} alt="" title="" />
                                                            </Button>
                                                        }
                                                        {this.state.first_answer == '0' &&
                                                            <Button size="medium" color="primary" onClick={() => this.skilltest_one('8')}>
                                                                8
                                                        </Button>
                                                        }
                                                        {this.state.first_answer == '4' &&
                                                            <Button size="medium" color="primary" onClick={() => this.skilltest_one('8')}>
                                                                8
                                                        </Button>
                                                        }
                                                        {this.state.first_answer == '8' &&
                                                            <Button size="medium" color="primary" className="rightAnswer">
                                                                <img style={{ width: "13px" }} src={require('../../assets/images/Vector112.png')} alt="" title="" />
                                                            </Button>
                                                        }
                                                    </div>
                                                    {this.state.first_answer == '4' &&
                                                        <div className="wrong_try"><p>{dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.try_again.en : "Wrong, try again"}</p></div>
                                                    }
                                                    <div className="skill_declare">
                                                        <p>
                                                            {dynamic_content.static_text.prize_detail ? ReactHtmlParser(dynamic_content.static_text.prize_detail.skill_declare.en) : "Canadian contest law requires that you answer a skill testing question before redeeming your prize. Please answer the above by selecting the correct answer at each stage."}
                                                        </p>
                                                    </div>
                                                </div>

                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    {/* Skill test model */}


                                    {/* Skill Right answer model */}
                                    <div>
                                        <Modal isOpen={this.state.modal_two} toggle={this.skilltest_two_open} className="skilltestmodel">
                                            <ModalBody className="skilltestBody">
                                                <div className="support_model">
                                                    <div className="skilltest_label">
                                                        <label>{dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.answer_skill_test.en : "Answer the skill test to claim your prize"}</label>
                                                    </div>
                                                    <div className="skill_query">
                                                        <span>2*</span><p>(2+2)=</p>
                                                    </div>
                                                    <div className="skilltest_content">

                                                        {this.state.second_answer == '0' &&
                                                            <Button size="medium" color="primary"
                                                                onClick={() => this.skilltest_two('8')}
                                                            >8
                                                        </Button>
                                                        }
                                                        {this.state.second_answer == '4' &&
                                                            <Button size="medium" color="primary"
                                                                onClick={() => this.skilltest_two('8')}
                                                            >8
                                                        </Button>
                                                        }
                                                        {this.state.second_answer == '8' &&
                                                            <Button size="medium" color="primary" className="rightAnswer">
                                                                <img style={{ width: "13px" }} src={require('../../assets/images/Vector112.png')} alt="" title="" />
                                                            </Button>
                                                        }
                                                        {this.state.second_answer == '0' &&
                                                            <Button size="medium" color="primary" onClick={() => this.skilltest_two('4')}>
                                                                4
                                                        </Button>
                                                        }
                                                        {this.state.second_answer == '8' &&
                                                            <Button size="medium" color="primary" onClick={() => this.skilltest_two('4')}>
                                                                4
                                                        </Button>
                                                        }

                                                        {this.state.second_answer == '4' &&
                                                            <Button size="medium" color="primary" className="rightwrong">
                                                                <img style={{ width: "11px" }} src={url.family_one_image_url + 'Group202.png'} alt="" title="" />
                                                            </Button>
                                                        }
                                                    </div>
                                                    {this.state.second_answer == '4' &&
                                                        <div className="wrong_try"><p>{dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.try_again.en : "Wrong, try again"}</p></div>
                                                    }
                                                    <div className="skill_declare">
                                                        <p>
                                                            {dynamic_content.static_text.prize_detail ? ReactHtmlParser(dynamic_content.static_text.prize_detail.skill_declare.en) : "Canadian contest law requires that you answer a skill testing question before redeeming your prize. Please answer the above by selecting the correct answer at each stage."}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    {/* Skill Right answer model */}

                                    {/* Skill Right answer model */}
                                    <div>
                                        <Modal isOpen={this.state.modal_three} toggle={this.skilltest_three_open} className="skilltestmodel">
                                            <ModalBody className="skilltestBody">
                                                <div className="support_model">
                                                    <div className="skilltest_label">
                                                        <label>{dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.answer_skill_test.en : "Answer the skill test to claim your prize"}</label>
                                                    </div>
                                                    <div className="skill_query">
                                                        <p>2*4=</p>
                                                    </div>
                                                    <div className="skilltest_content">

                                                        {this.state.third_answer == '0' &&
                                                            <Button size="medium" color="primary" onClick={() => this.skilltest_three('8')}>
                                                                8
                                                        </Button>
                                                        }
                                                        {this.state.third_answer == '5' &&
                                                            <Button size="medium" color="primary" onClick={() => this.skilltest_three('8')}>
                                                                8
                                                        </Button>
                                                        }
                                                        {this.state.third_answer == '8' &&
                                                            <Button size="medium" color="primary" className="rightAnswer">
                                                                <img style={{ width: "13px" }} src={require('../../assets/images/Vector112.png')} alt="" title="" />
                                                            </Button>
                                                        }
                                                        {this.state.third_answer == '5' &&
                                                            <Button size="medium" color="primary" className="rightwrong">
                                                                <img style={{ width: "11px" }} src={url.family_one_image_url + 'Group202.png'} alt="" title="" />
                                                            </Button>
                                                        }
                                                        {this.state.third_answer == '0' &&
                                                            <Button size="medium" color="primary"
                                                                onClick={() => this.skilltest_three('5')}
                                                            >5
                                                        </Button>
                                                        }
                                                        {this.state.third_answer == '8' &&
                                                            <Button size="medium" color="primary"
                                                                onClick={() => this.skilltest_three('5')}
                                                            >5
                                                        </Button>
                                                        }

                                                    </div>
                                                    {this.state.third_answer == '5' &&
                                                        <div className="wrong_try"><p>{dynamic_content.static_text.prize_detail ? dynamic_content.static_text.prize_detail.try_again.en : "Wrong, try again"}</p></div>
                                                    }
                                                    <div className="skill_declare">
                                                        <p>
                                                            {dynamic_content.static_text.prize_detail ? ReactHtmlParser(dynamic_content.static_text.prize_detail.skill_declare.en) : "Canadian contest law requires that you answer a skill testing question before redeeming your prize. Please answer the above by selecting the correct answer at each stage."}
                                                        </p>
                                                    </div>
                                                </div>
                                            </ModalBody>
                                        </Modal>
                                    </div>
                                    {/* Skill Right answer model */}
                                    {/* <div>
                                    <Modal isOpen={this.state.modal_four} toggle={this.skilltest_four_open} className="skilltestmodel_address">
                                        <ModalBody className="skilltestBody">

                                            <div className="support_model">
                                                <Address Back={this.handleBack.bind(this)} Continue={this.handleAdressContinue.bind(this)} />
                                            </div>
                                        </ModalBody>
                                    </Modal>
                                </div> */}
                                </div>
                            ))}
                        </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}
export default prize_detail;

