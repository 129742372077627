import functions from '../assets/functions/common_functions';
import axios from 'axios'
let ReactPixel = null;

export const initFacebookPixel = (pixelId) => {
  let event_timestamp = Math.floor(Date.now() / 1000); // Current time in UNIX timestamp
  if (typeof window !== 'undefined') {
    // Dynamically import the library only on the client
    import('react-facebook-pixel').then((module) => {
      ReactPixel = module.default;
      ReactPixel.init(pixelId);
      // Pass custom data with event_timestamp in the pageView call
      ReactPixel.pageView({ ts: event_timestamp });
      trackEventViaConversionAPI("PageView", event_timestamp);
    });
  }
};

export const trackEvent = (event, data = {}) => {

  if (typeof window === 'undefined' || !ReactPixel) return;
  try {
    let event_timestamp = Math.floor(Date.now() / 1000);
    // Add event_time to data and track the event using ReactPixel
    data.currency = 'USD';
    data.value = 0.00;
    const eventData = {
      ...data,
      ts: event_timestamp // Current time in UNIX timestamp
    };
    ReactPixel.track(event, eventData);
    trackEventViaConversionAPI(event, event_timestamp)
  } catch (error) {
    console.error('Error tracking event:', error);
  }
};

 // Fetch the IP address
 const getIpAddress = async () => {
  try {
    const response = await fetch("https://api64.ipify.org?format=json");
    // const response = await fetch("https://api.ipify.org?format=json");
    const data = await response.json();
    return data.ip;
  } catch (error) {
    console.error("Error fetching IP address:", error);
    return null;
  }
};

async function trackEventViaConversionAPI(event, event_timestamp) {
  try{
    // Retrieve cookies and local storage data
    const _fbp = functions.getCookie('_fbp');
    const _fbc = functions.getCookie('_fbc');
    const event_source_url = window.location.href;
    const fb_login_id = functions.getCookie('facebook_id')
    // Construct final JSON payload
    const final_json = {
      fb_login_id: fb_login_id,
      user_agent : navigator.userAgent,
      ip_address: await getIpAddress(),
      event_from: 'app2',
      event_source_url,
      event_name: event,
      event_timestamp: event_timestamp,
      fbp: _fbp,
      fbc: _fbc,
      user_data: {
        email: localStorage.getItem('email') || null,
        phone: localStorage.getItem('phone_number') || null,
        firstName: localStorage.getItem('given_name') || null,
        lastName: localStorage.getItem('family_name') || null
      },
      auto_id: localStorage.getItem('auto_id') || null,
    };
    // Invoke the App Runner API with the constructed payload
    functions.invokeAppRunnerFBCAPI(final_json, (res) => {

    });
  }catch(error){
    console.error('Error tracking event:', error);
  }
}

export async function updateSubInCamPTracking (sub_id){
  try{
    
    const camp_id = sessionStorage.getItem('fb_camp_id');
    const fb_camp_program_id = sessionStorage.getItem('fb_camp_program_id');
    if(!camp_id || !fb_camp_program_id || !sub_id) return

    const lambdaUrl = 'https://sxql70itni.execute-api.us-east-1.amazonaws.com/prod/fbCampTracking';
    const lambdaData= {
      camp_id,
      program_id : fb_camp_program_id,
      action:'set_sub_id',
      sub_id
    }
    axios.post(lambdaUrl, lambdaData).then((result)=>{
      if(result?.data?.success === 1){
        sessionStorage.removeItem('fb_camp_program_id');
        sessionStorage.removeItem('fb_camp_id');
      }
    })
    }catch(error){

  }
}