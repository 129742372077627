import { InputAdornment, withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
// import { RemoveRedEye } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
var md5 = require('md5');

const styles = theme => ({
    eye: {
        cursor: 'pointer',
    },
});

class PasswordInput extends Component {
    constructor(props) {
        super(props);

        this.state = {
            passwordIsMasked: true,
        };
    }

    togglePasswordMask = () => {
        this.setState(prevState => ({
            passwordIsMasked: !prevState.passwordIsMasked,
        }));
    };

    render() {
        const { classes } = this.props;
        const { passwordIsMasked } = this.state;

        return (
            <TextField
                type={passwordIsMasked ? 'password' : 'text'}
                {...this.props}
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            {/* <RemoveRedEye className={classes.eye} onClick={this.togglePasswordMask} /> */}
                            {passwordIsMasked ? (
                                <img src={require('../../assets/images/eyeShow.svg')} alt="" title="" className={classes.eye} onClick={this.togglePasswordMask} />
                            ) : (
                                <img src={require('../../assets/images/eyeHide.svg')} alt="" title="" className={classes.eye} onClick={this.togglePasswordMask} />
                            )}
                        </InputAdornment>
                    ),
                }}
            />
        );
    }
}

PasswordInput.propTypes = {
    classes: PropTypes.object.isRequired,
    value: PropTypes.func.isRequired,
};

PasswordInput = withStyles(styles)(PasswordInput);

/* --------------------------------------------------------- */

class ChangePassword extends Component {
    constructor(props) {
        super(props);
        functions.handleBackOne(this, 'change_password')
        this.state = {
            password: '',
            confirm_password: '',
            focus_password: true,
            focus_confirm_password: false,
            go_next: true,
            dynamic_content: "",
            success: false
        };
    }

    myTrim = (x) => {
        return x.replace(/\s/g, '');
    }

    handleUpdate = () => {
        let value = md5(this.state.password);
        let userdata = [{ Name: "custom:user_password", Value: value }]
        let dynamo_userdata = { password: true };
        functions.updateUserData(this, userdata, dynamo_userdata)
        functions.pushToRoute(this, 'setting', 'change_password')
    }

    CheckPassword(inputtxt) {
        var pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (inputtxt.match(pass)) {
            // alert('Correct, try another...')
            this.setState({ focus_password: '' })
        }
        else {
            this.setState({ focus_password: this.state.dynamic_content.static_text.change_password.password_validation.en, focus_confirm_password: '' })
        }
        if (this.state.confirm_password === inputtxt || this.state.confirm_password === "") {
            //Update code
            this.setState({ focus_confirm_password: '' });

        } else {
            this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.change_password.password_not_match.en });
        }
    }

    CheckConfirmPassword = (inputtxt) => {
        // var pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        // if (inputtxt.match(pass)) {
        //     // alert('Correct, try another...')
        //     this.setState({ focus_confirm_password: '' })
        // }
        // else {
        //     this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.change_password.password_not_match.en, focus_password: '' })
        // }
        if (this.state.password === inputtxt) {
            this.setState({ focus_confirm_password: '' });

        } else {
            this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.change_password.password_not_match.en });
        }
    }

    handleContinue() {
        let self = this
        let success_action = "User clicked on continue button on change_password page"
        let image = 'continue_button_change_password_page.png'
        functions.logUserActivity(success_action, image);
        var pass = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/;
        if (this.state.password === '' && this.state.confirm_password === '') {
            this.setState({ focus_password: this.state.dynamic_content.static_text.change_password.password_validation.en, focus_confirm_password: '' });
        }
        else {
            if (this.state.password !== '' && this.state.confirm_password === '') {
                if (this.state.password.match(pass)) {
                    this.setState({ focus_password: '' })
                } else {
                    this.setState({ focus_password: this.state.dynamic_content.static_text.change_password.password_validation.en, focus_confirm_password: '' });
                }
            }
            else if (this.state.confirm_password !== '' && this.state.password === '') {
                if (this.state.confirm_password.match(pass)) {
                    this.setState({ focus_confirm_password: '' })

                } else {
                    this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.change_password.password_not_match.en, focus_password: '' });
                }
            }
            else if (this.state.confirm_password !== '' && this.state.password !== '') {
                if (this.state.password === this.state.confirm_password) {
                    //Update code
                    if (this.state.password.match(pass)) {
                        this.setState({ focus_password: '', success: true })

                        setTimeout(() => {
                            this.handleUpdate()
                        }, 1000)

                    } else {
                        this.setState({ focus_password: this.state.dynamic_content.static_text.change_password.password_validation.en, focus_confirm_password: this.state.dynamic_content.static_text.change_password.password_not_match.en });
                    }
                } else {
                    this.setState({ focus_confirm_password: this.state.dynamic_content.static_text.change_password.password_not_match.en, focus_password: '' });
                }
            }
        }
    }

    componentDidMount = () => {
        let success_action = "User landed on change_password page"
        let image = 'change_password_page_landing.png'
        functions.logUserActivity(success_action, image);
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content !== "") {
            this.setState({ dynamic_content: dynamic_content })
        }
    }

    render() {
        const { password, confirm_password } = this.state;
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                    <div className="common_mainAddOn">
                        {dynamic_content !== "" &&
                            <div className="common_main">
                                <div className="sign_backlink sign_backlinkNw" onClick={() => { functions.pushToRoute(this, "setting", 'change_password') }}>
                                    <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                </div>
                                <div className="profileTp">
                                    <h1>Settings</h1>
                                    {dynamic_content.static_text.change_password && dynamic_content.static_text.change_password.change_password.en ?
                                        <h2>{dynamic_content.static_text.change_password.change_password.en}</h2> :
                                        <h2>Change Password</h2>
                                    }
                                </div>
                                <div className="usrChngPassUpr">
                                    <form autoComplete="off">

                                        <div className="signup_password usrChngPassUpr">
                                            <div className='usrChngPassInr'>
                                                <div><label>
                                                    {/* {dynamic_content.static_text.change_password ? dynamic_content.static_text.change_password.change_password.en : "New password"} */}
                                                    New Password
                                                </label></div>
                                                <PasswordInput
                                                    //label={dynamic_content.static_text.change_password ? dynamic_content.static_text.change_password.change_password.en : "New password"}
                                                    placeholder="New password"
                                                    name="password"
                                                    value={password}
                                                    autoFocus={true}
                                                    inputRef={(input) => { this.password = input; }}
                                                    onChange={(e) => { this.setState({ password: this.myTrim(e.target.value), go_next: false }) }}
                                                    onKeyUp={event => {
                                                       
                                                        if (event.keyCode === 32) {
                                                            event.preventDefault()
                                                        }
                                                        if (event.key === "Enter" && event.keyCode !== 32) {
                                                            this.confirm_password.focus()
                                                        }
                                                        else if (event.key && event.keyCode !== 32) {
                                                            this.CheckPassword(this.state.password)
                                                        }
                                                    }}
                                                    className="signuptype_password"
                                                />
                                                {this.state.focus_password.length > 1 &&
                                                    <p>{this.state.focus_password}</p>
                                                }
                                            </div>

                                            <div className='usrChngPassInr'>
                                                <div><label>
                                                    {/* {dynamic_content.static_text.change_password ? dynamic_content.static_text.change_password.confirm_password.en : "Password Confirmation"} */}
                                                    Password Confirmation
                                                </label></div>
                                                <PasswordInput
                                                    //label={dynamic_content.static_text.change_password ? dynamic_content.static_text.change_password.confirm_password.en : "Confirm password"}
                                                    placeholder="Confirm password"
                                                    name="confirm_password"
                                                    value={confirm_password}
                                                    inputRef={(input) => { this.confirm_password = input; }}
                                                    onChange={(e) => { this.setState({ confirm_password: this.myTrim(e.target.value), go_next: false }) }}
                                                    onKeyUp={event => {
                                                        if (event.keyCode === 32) {
                                                            event.preventDefault()
                                                        }
                                                        if (event.key === "Enter" && event.keyCode !== 32) {
                                                            this.handleContinue()
                                                        }
                                                        else if (event.key && event.keyCode !== 32) {
                                                            this.CheckConfirmPassword(this.state.confirm_password)
                                                        }

                                                    }}
                                                    className="signuptype_password"
                                                />
                                                {/* <TextField
                                            autoComplete='off'
                                            label={dynamic_content.static_text.change_password ? dynamic_content.static_text.change_password.confirm_password.en : "Confirm password"}
                                            type="password"
                                            margin="normal"
                                            inputRef={(input) => { this.confirm_password = input; }}
                                            onChange={(e) => { this.setState({ confirm_password: e.target.value, go_next: false }) }}
                                            onKeyUp={event => {
                                                if (event.key === "Enter") {
                                                    this.handleContinue()
                                                }
                                                else if (event.key) {
                                                    this.CheckConfirmPassword(this.state.confirm_password)
                                                }
                                            }}
                                            className="logup_first"
                                        /> */}
                                                {this.state.confirm_password.length > 0 && this.state.focus_confirm_password.length > 1 &&
                                                    <p>{this.state.focus_confirm_password}</p>
                                                }
                                                {this.state.success &&
                                                    <div className="success_message"><p>{dynamic_content.static_text.change_password.change_password_success.en}</p></div>
                                                }
                                            </div>
                                        </div>
                                        {!this.state.success &&
                                            <div className="signup_submit">
                                                <Button size="medium" color="primary" onClick={() => { this.handleContinue() }}>
                                                    {dynamic_content.static_text.change_password ? dynamic_content.static_text.change_password.continue.en : "Continue"}
                                                </Button>
                                            </div>
                                        }
                                    </form>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default ChangePassword;
