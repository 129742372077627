import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import cookie from 'react-cookies';
import ReactPlayer from 'react-player';
// import { FacebookProvider, Comments } from 'react-facebook';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import url from '../../assets/url';
import functions from '../../assets/functions/common_functions';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import Iframe from 'react-iframe'
import Footer from '../innerpages/footer';

const C = CSSPlugin;


const CalculateHeight = (width, dimention) => {
    dimention = dimention.split(':');
    let newHeight = (width / dimention[0]) * dimention[1];
    newHeight = parseInt(newHeight);
    return newHeight + 'px';
}


class story_detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            session_entry: cookie.load("auto_id") ? false : true,
            dynamic_content: "",
            show_video: false,
            program: {},
            cookie_auto_id: cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id") ? localStorage.getItem("auto_id") : null
        };
    }

    componentWillMount = () => {
        if (localStorage.getItem("current_state") === "story_detail") {
        }
        else {
            // Redirect to "/" if current_state is not "story_detail"
            this.props.history.push("/");
            // Reload the window to ensure any necessary updates or resets
            window.location.reload();
          }
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }

        // let success_action = "User landed on story detail page"
        // functions.logUserActivity(success_action);
        let self = this;
        let state_program_array = this.state.program_array
        // Set program related info
        let program = this.props.program;
        let previous_path = localStorage.getItem("previous_state")
        if (previous_path == "main_index") {
            let perk_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
            let entered_id = localStorage.getItem("entered_id") ? JSON.parse(localStorage.getItem("entered_id")) : [];
            program = perk_list.find(pro => pro.program_id == entered_id)
        }
        if (!program) {
            let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
            let entered_id = localStorage.getItem("session_entered_id") ? JSON.parse(localStorage.getItem("session_entered_id")) : '';
            program = program_list.find(pro => pro.program_id == entered_id)
        }

        let program_array = [];
        program_array.push(program)

        if (this.state.session_entry == false) {
            functions.getShareLink(this, program.program_id, cookie.load("auto_id"), function (response_json) {
                let response = JSON.parse(response_json);
                program_array[0].facebook_link = response.facebook_link;
                self.setState({ program_array: program_array, program_type: program?.program_type ?? '' })
            })
        } else {
            this.setState({ program_array: program_array, program_type: program?.program_type ?? '' })
        }
        this.setState({ program })
    }

    // fbcreateScript() {
    //     // load the sdk
    //     window.fbAsyncInit = this.fbAsyncInit;
    //     const script = document.createElement('script');
    //     script.src = 'https://connect.facebook.net/en_US/sdk.js';
    //     script.async = true;
    //     script.onload = this.initFB;
    //     document.body.appendChild(script);
    // }

    // fbAsyncInit() {
    //     // init the fb sdk client
    //     const fb = window.FB;
    //     fb.init({
    //         appId: '367702427421660',
    //         cookie: true,
    //         xfbml: true,
    //         version: 'v4.0'
    //     });
    // }

    // initFB() {
    //     const fb = window.FB;
    //     // fb.api('/me', { fields: 'name,email,first_name,last_name,gender,birthday' }, response => {
    //     fb.api(
    //         '/2611353482232364/comments',
    //         'GET',
    //         { 'access_token': "EAAaa6kZBJqJQBANUyRULbAAqxd3aqJDZC0NRHWG3hDaRZCH3O8Gl5r6czjo87ihyqOWVe4vWTlGHnQ2pxZCNEHiFIgEJZBg8E8N15RMRK7UfP4v2ZCs1KloqYosKTc1UmZCgvhZBQeCNt7xZBAdh29ozKWMzU8PrbaDCOLya64HZBkaiUHZCwCWryZBs3VfZArkLBjIsZD" },
    //         response => {
    //         }
    //     );
    // }

    // getFbComments = () => {
    //     const fb = window.FB;
    //     // fb.api('/me', { fields: 'name,email,first_name,last_name,gender,birthday' }, response => {
    //     fb.api(
    //         '/2611353482232364/comments',
    //         'GET',
    //         { 'access_token': "EAAaa6kZBJqJQBAFIFZCYoGugwEhiTZBQp5tfg9pfLexdyEdZB4R6GDt8GjFXVuylWWZBZA0dMnmqn03ZC5H1Dv3ox1y5DcgO0ZBzzHORZAJrvYujAdY6CBiUdEEORGu8Vpklk5GGtQZAa8VFV11ZBrlsSVPhtFPuTHBqfN483vcg6FETL8CAPEaJP4dIZAmXgShwZAZABrIwnCGZA41Fw2eeZBQq1Bez" },
    //         response => {
    //         }
    //     );
    // }

    // // 2611353482232364/comments
    // postFbComment = () => {
    //     const fb = window.FB;
    //     fb.api(
    //         "/2611353482232364/comments",
    //         "POST",
    //         {
    //             "message": "Done"
    //         },
    //         {
    //             'access_token': "EAAaa6kZBJqJQBAFIFZCYoGugwEhiTZBQp5tfg9pfLexdyEdZB4R6GDt8GjFXVuylWWZBZA0dMnmqn03ZC5H1Dv3ox1y5DcgO0ZBzzHORZAJrvYujAdY6CBiUdEEORGu8Vpklk5GGtQZAa8VFV11ZBrlsSVPhtFPuTHBqfN483vcg6FETL8CAPEaJP4dIZAmXgShwZAZABrIwnCGZA41Fw2eeZBQq1Bez"
    //         },
    //         response => {
    //             if (response && !response.error) {
    //             }
    //         }
    //     );
    // }

    handleProgramClick = (program) => {
        // let self = this;

        // if (!window.FB) {
        //     this.fbcreateScript();
        // }
        // else {
        //     this.initFB();
        // }

    }

    handleClick = (program) => {
        let success_action = "User clicked on enter button on story detail page"
        let image = 'enter_button_stroy_detail_page.png'
        functions.logUserActivity(success_action, image);
        this.handleAnimation();
        let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id");
        sub_id = sub_id ? sub_id : "";
        if (sub_id != "") {
            this.setState({ loader: true });
            let is_sub_id = 'yes';

            let program_id = program.program_id;
            let partner_id = program.partner_id;
            let term_id = program.term_id ? program.term_id : "";
            let program_type = program?.program_type ?? '';
            let registration_source = program.registration_source ? program.registration_source : ""
            let point_type = 'entry';
            let self = this;
            let is_direct = 1;
            let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
            let user_eligible = ""
            functions.contestEntry(self, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id,"", "", null, function (response_json) {
                let response = JSON.parse(response_json);
                if (response.status == 200) {
                    self.setState({ loader: false });
                    self.removeAnimationEffect();
                }
            })
            let program_array = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : [];
            if (program_array) {
                var program_index = program_array.findIndex(p => p.program_id == program_id)
                program_array[program_index].has_enter = 1;
                program_array[program_index].pending_fields = [];
                let state_program_array = self.state.program_array
                state_program_array[0].has_enter = 1;
                state_program_array[0].pending_fields = [];
                self.setState({ program_array: state_program_array })
                localStorage.removeItem("session_entered_id")
                localStorage.removeItem("pending_id")
                localStorage.setItem("program_list", JSON.stringify(program_array))
            }
            let program_only = program ? program : [];
            if (program_only) {
                program_only.has_enter = 1;
                program_only.pending_fields = [];
                localStorage.removeItem("session_entered_id")
                localStorage.removeItem("pending_id")
                localStorage.setItem("program", JSON.stringify(program_only))
                self.handleAnimationAfterEntry();
            }
        }
        else {
            let is_sub_id = 'no';
            let sub_id = is_sub_id == 'yes' ? cookie.load("auto_id") : cookie.load("session_id");
            let program_id = program.program_id;
            let term_id = program.term_id ? program.term_id : "";
            let partner_id = program.partner_id;
            let program_type = program?.program_type ?? '';
            let registration_source = program.registration_source ? program.registration_source : ""
            let point_type = 'entry';
            let self = this;
            let is_direct = 1;
            if (program?.is_lock_program != 1) {
                let user_eligible = ""
                let tracking_id = cookie.load("tracking_id") ? cookie.load("tracking_id") : "";
                functions.contestEntry(this, is_sub_id, sub_id, tracking_id, program_id, partner_id, registration_source, point_type, is_direct, user_eligible, '', program_type, term_id, "", "", "", function (response_json) {
                    let response = JSON.parse(response_json);
                    if (response.status == 200) {
                        localStorage.setItem("session_entered_id", program_id)
                        self.setState({ loader: false });
                    }
                })
            }
            // functions.pushToRoute(self, 'home', 'story_detail')
            this.props.history.push({
                pathname: '/home'
            })
        }
    }

    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.instant_redem2 button, .freebies_redem button', 0.4, { opacity: 1 }, { opacity: 0, y: -40 })
            .fromTo('.instant_hend2', 0.4, { opacity: 1 }, { opacity: 0 }, 0.2)
            .fromTo('.ht-200', 0.4, { display: "block" }, { height: "200px" })
            .to('.program_entry_loader', 0.1, { opacity: 1 }, 0)
    }

    handleAnimationAfterEntry = () => {
        let TL11 = new TimelineMax();
        TL11.fromTo('.fiveone_pts3', 1, { opacity: 0, y: 20 }, { opacity: 1, y: 0 })
            .fromTo('.social_connect_upr', 1, { opacity: 0 }, { opacity: 1 })
            .fromTo('.borderProgress, .one_social, .social_activleft', 1, { opacity: 0, scaleX: 0.5, transformOrigin: "right" }, { opacity: 1, scaleX: 1, transformOrigin: "left" }, 1)
            .fromTo('.one_social_connect', 1, { opacity: 0, scaleX: 0.5, transformOrigin: "left" }, { opacity: 1, scaleX: 1, transformOrigin: "right" }, 1)

            .fromTo('.social_activityleft', 1, { opacity: 0, scaleX: 0.5, transformOrigin: "right" }, { opacity: 1, scaleX: 1, transformOrigin: "left" }, 1.8)
            .fromTo('.social_activityright', 1, { opacity: 0, scaleX: 0.5, transformOrigin: "left" }, { opacity: 1, scaleX: 1, transformOrigin: "right" }, 1.8)

            .to('.ht-200', 0.4, { display: "none" })
            // .fromTo('.instant_redem2 button,.instant_hend2,.freebies_redem button', 0.1, { opacity: 1 }, 0)
            .to('.instant_redem2 button,.freebies_redem button', 0.1, { y: 0 })
            .to('.instant_redem2 button,.instant_hend2,.freebies_redem button', 0.1, { opacity: 1 })
            .to('.program_entry_loader', 0.1, { opacity: 0 }, 0)
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }

    handleBackSession = () => {
        let action = "User clicked on back button of " + this.state.program.program_id + " on story detail page"
        let image = 'back_button_story_detail_page_' + this.state.program.program_id + '.png'
        functions.logUserActivity(action, image);
        let previous_state = localStorage.getItem("previous_state")
        if (previous_state == "main_index" && (localStorage.getItem("name") || localStorage.getItem("email"))) {
            functions.pushToRoute(this, 'main', 'story_detail')
        } else if (previous_state == "main_index" || previous_state == "story_detail") {
            functions.pushToRoute(this, "main_index", "story_detail")
        }
    }

    handleImageClick = () => {
        this.setState({ show_video: true })
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div id={this.state.program?.program_type + this.state.program?.program_id} className={"program-page hero-1 hero_" + this.state.program?.program_id}>
                {/* {dynamic_content != "" && */}
                <div className="row">
                    <div className="common_mainAddOn">
                        {this.state.program_array && this.state.program_array.length > 0 && this.state.program_array.map((program, index) => (
                            <div key={"program_array" + index} className="common_main detailspace_rmv_prc">

                                <div className="detailback_sectionStry cstm_dtlImges_display">
                                    <div className="detailback_section">
                                        <div className="story_back_button">
                                            <div className="backLinkWidth">
                                                <img src={url.family_one_image_url + 'Group147.1.png'} alt="" title="" />
                                            </div>
                                        </div>
                                        <div className="detailback_white"><a href={program?.facebook_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'ReplyIcon.png'} alt="" title="" /></a></div>
                                    </div>
                                    <div className="contests_dtlImges">
                                        <img onClick={() => { this.handleProgramClick(program) }} src={url.cloudflare_image_path + program.program_image + url.varient} alt="" title="" />
                                    </div>
                                </div>

                                {/* Start of this section */}

                                <div className="storiesData">
                                    <div className="dtl_babyImg">

                                        <div className="stryContents_wht">

                                            <div className="contests_dtlImges cstm_dtlImges">
                                                <div><img src={url.cloudflare_image_path + program.program_image + url.varient} alt="" title="" /></div>
                                                <div className="dtl_Imges cstm_dtl_Imges">

                                                    <div className="detailback_section detailback_sectionAdd">
                                                        <div className="story_back_button">
                                                            <div onClick={() => { this.handleBackSession() }} className="backLinkWidth close_btn">
                                                                <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                                                            </div>
                                                        </div>
                                                        {!this.state.session_entry &&
                                                            <div className="detailback_white"><a href={program?.facebook_link} rel="noopener noreferrer" target="_blank"><img src={url.family_one_image_url + 'ReplyIcon.png'} alt="" title="" /></a></div>
                                                        }
                                                    </div>

                                                    <div className="cstm_ImgesCntnt">
                                                        <div className="dtl_ImgesCntnt story_label program_title01">
                                                            <label>{ReactHtmlParser(program.program_title)}</label>
                                                            <p>{ReactHtmlParser(program.program_subtitle)}</p>
                                                            {program.has_enter != 1 && program.entry_allowed == 1 &&
                                                                <Button size="medium" color="primary" onClick={() => { this.handleClick(program) }}>
                                                                    {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.enter.en : "Enter"}
                                                                </Button>
                                                            }
                                                            {program.has_enter == 1 && program.entry_allowed == 1 &&
                                                                <Button size="medium" color="primary">
                                                                    {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.entered.en : "Entered"}
                                                                </Button>
                                                            }
                                                        </div>
                                                    </div>

                                                </div>
                                                <div className="program_detail_logo"></div>
                                            </div>

                                            {/* <div className="stryContents">
                                                <label>Lorem Ipsum</label>
                                                <p>simply dummy text</p>
                                            </div> */}
                                        </div>

                                    </div>

                                    <div className="dtl_details_sub">
                                        <div className="dtl_descp_sub">
                                            <label>{ReactHtmlParser(program.story_subtitle1)}</label>
                                            {ReactHtmlParser(program.story_paragraph1)}
                                        </div>
                                        {program.story_media && program.story_media.length > 0 && program.story_media.map((element, index) => (
                                            <div key={"program_array_story_" + index} className="story_media">
                                                {element.url && element.url != "" &&
                                                    <div key={"story_media" + index} className="vdo_show" style={{ marginLeft: "3px" }}>
                                                        {program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook') && element.type == "video" && this.state.show_video == false &&
                                                            <div style={{ position: "relative" }} >
                                                                <img style={{ borderRadius: "20px", height: "auto" }} width="324px" src={url.cloudflare_image_path + program.program_image + url.varient} alt="" title="" />
                                                                <img onClick={() => { this.handleImageClick() }} className="play_button" src={url.cloudflare_image_path + "Subtractplay.png" + url.varient} alt="" title="" />
                                                            </div>
                                                        }
                                                        {program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook') && element.type == "video" && this.state.show_video == true &&
                                                            <Iframe url={element.url + "&autoplay=1&mute=1"}
                                                                width="324px"
                                                                height={CalculateHeight(324, program.video_dimention ? program.video_dimention : '1:1')}
                                                                id={"iframe_story_media" + index}
                                                                className="iframe_story_media"
                                                                scrolling="no"
                                                                frameborder="0"
                                                                allow='autoplay; encrypted-media'
                                                                title='video'
                                                                allowTransparency="true"
                                                                data-video-aspect-ratio="5:9"
                                                                autoplay="true"
                                                                webkitallowfullscreen="true"
                                                                mozallowfullscreen="true"
                                                                allowfullscreen="true"
                                                            />
                                                        }
                                                        {!(program.media_source && (program.media_source == 'youtube' || program.media_source == 'facebook')) && element.type == "video" &&
                                                            <ReactPlayer
                                                                config={{
                                                                    youtube: {
                                                                        playerVars: { showinfo: 0 }
                                                                    }
                                                                }}
                                                                className="video_story" light={true} controls={true} url={element.url} playing />
                                                        }
                                                        {element.type != "video" &&
                                                            <img className="video_story" src={element.url} alt="" title="" />
                                                        }
                                                    </div>
                                                }
                                            </div>
                                        ))}

                                        <div className="dtl_descp_sub">
                                            <label>{ReactHtmlParser(program.story_subtitle2)}</label>
                                            {ReactHtmlParser(program.story_paragraph2)}
                                        </div>
                                        {!this.state.session_entry &&
                                            <div className="dtl_suba_redem">

                                                <a className="story_share" href={program.facebook_link} rel="noopener noreferrer" target="_blank">Share</a>

                                                {program.has_enter != 1 && program.entry_allowed == 1 &&
                                                    <Button size="medium" color="primary" className="dtl_subaenter" onClick={() => { this.handleClick(program) }}>
                                                        {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.enter.en : "Enter"}
                                                    </Button>
                                                }
                                                {program.has_enter == 1 && program.entry_allowed == 1 &&
                                                    <Button size="medium" color="primary" className="dtl_subaenter">
                                                        {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.entered.en : "Entered"}
                                                    </Button>
                                                }
                                            </div>
                                        }
                                        {/* {!this.state.session_entry &&
                                                <div className="fb_connect">
                                                    <div> <img src={url.family_one_image_url + 'Path.png'} alt="" title="" /> </div>
                                                    <p>{dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.facebook_comments.en : "Facebook comments"}</p>
                                                    <FacebookProvider appId="367702427421660">
                                                        <Comments href="https://www.facebook.com/WebnexusPankaj/posts/915966112113485" width="100" />
                                                    </FacebookProvider>
                                                </div>
                                            } */}
                                        {this.state.session_entry &&
                                            <div className="paddingbottom">
                                                <div className="hometwo_upr">
                                                    <div className="hometwo_hendupr">
                                                        <div className="hometwo_hend" onClick={() => { this.handleClick(program) }}>
                                                            <div className='startRocket'><img src={require('../../assets/images/rocketIconNw.png')} alt="" title="" /></div>
                                                            <h3>{dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.get_started.en : "Get Started"}</h3>
                                                            <p>{dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.login_to_redeem.en : "To redeem this offer you need login or sign up"}</p>
                                                        </div>
                                                    </div>
                                                    <div className="hometwo_redem">
                                                        <Button size="medium" color="primary" onClick={() => { this.handleClick(program) }}>
                                                            {dynamic_content && dynamic_content.static_text.story_detail ? dynamic_content.static_text.story_detail.get_started.en : "Get Started"}
                                                        </Button>
                                                    </div>
                                                </div>
                                                {this.state.cookie_auto_id && this.state.cookie_auto_id != null &&
                                                    <Footer props={this.props} />
                                                }
                                            </div>
                                        }

                                    </div>
                                </div>

                                {/* End of this section */}
                            </div>
                        ))}
                    </div>  
                </div>
                {/* } */}
            </div>
        );
    }
}
export default story_detail;