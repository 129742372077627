import React from 'react';
import '../../assets/css/style.css';
import Collapsible from 'react-collapsible';
import Button from '@material-ui/core/Button';
import { Modal, ModalBody } from 'reactstrap';
import TermsConditions from './terms';
import Support from './extrasupport';
import Footer from './../innerpages/footer';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import axios from "axios";
class Help extends React.Component {
  constructor(props) {
    super(props);
    // functions.handleBackOne(this, 'help')
    this.state = {
      show_terms: false,
      show_support: false,
      show_error_message: "",
      open_dailog: false,
      show_account_status: false,
      account_close_link_sent: false,
      second_modal: false,
      Partners: [],
      Partner_Categories: [],
      Program_Categories: [],
      Notification_Types: [],
      blocked_notifications: {},
      dynamic_content: "",
      open_dailog: false,
      ischecked: false,
      ischeckedsecond: false,
      second_modal: false,
      confirm: "",
      close_my_account_tnc1: "I am aware that I may have consented to have family.one transfer specific details about me to specified third parties. In these cases, for legal reasons including CASL compliance, I am aware that family.one retains a log of the data transfer and consent specifics.",
      close_my_account_tnc2: "I accept that should I wish to also unenroll from any third party programs, I must contact said programs directly.",
      close_account_tnc1_accept_date: "",
      close_account_tnc1_accept_time: "",
      close_account_tnc1_accept_timestamp: "",
      close_account_tnc2_accept_date: "",
      close_account_tnc2_accept_time: "",
      close_account_tnc2_accept_timestamp: "",
      close_account_confirm_date: "",
      close_account_confirm_time: "",
      close_account_confirm_timestamp: "",
      error_found: false,
      response_message: "",
      account_close_link_sent: false,
      loader: true,

      show_messenger: false,
      dynamic_content: ""
    };
    this.editprofile = this.editprofile.bind(this);
    this.toggleAddchild = this.toggleAddchild.bind(this);
    this.handlePreviousPage = this.handlePreviousPage.bind(this);
    this.handleState = this.handleState.bind(this);
    this.terms = this.terms.bind(this);
  }

  toggle() {
    this.setState({
      open_dailog: !this.state.open_dailog
    })
  }


  handleInputChange(event, event_name) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;


    if (name == 'ischecked' && value == true) {
      this.setState({ "close_account_tnc1_accept_date": new Date().toLocaleDateString(), "close_account_tnc1_accept_time": new Date().toLocaleTimeString(), "close_account_tnc1_accept_timestamp": new Date().getTime(), show_error_message: "" })
    }
    if (name == 'ischeckedsecond' && value == true) {
      this.setState({ "close_account_tnc2_accept_date": new Date().toLocaleDateString(), "close_account_tnc2_accept_time": new Date().toLocaleTimeString(), "close_account_tnc2_accept_timestamp": new Date().getTime() })
    }

    this.setState({
      [event_name]: value,
      "error_found": false,
      "response_message": ""
    });
  }

  handleConfirm = () => {
    if (this.state.ischecked == true) {
        this.setState({ second_modal: true })
        this.setState({ show_error_message: "" })

    }
   
        else{
            this.setState({ show_error_message: "Please select checkbox..." })

    }
}

  handleDone = () => {
    let self = this;
    if (this.state.confirm == "CLOSE") {
      this.setState({ show_error_message: "" })

      this.setState({
        "error_found": false,
        "response_message": "",
        "loader": true
      })

      let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
      let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
      let finaljson = {
        "email": email
      }

      let data_to_close_account = {
        "action": "send-close-account-link",
        "sub_id": sub_id,
        "email": email,
        "terms_and_conditions_1": self.state.close_my_account_tnc1,
        "terms_and_conditions_2": self.state.close_my_account_tnc2,
        "terms_and_condition_1_accept_date": self.state.close_account_tnc1_accept_date,
        "terms_and_condition_1_accept_time": self.state.close_account_tnc1_accept_time,
        "terms_and_condition_1_accept_timestamp": self.state.close_account_tnc1_accept_timestamp,
        "terms_and_condition_2_accept_date": new Date().toLocaleDateString(),
        "terms_and_condition_2_accept_time": new Date().toLocaleTimeString(),
        "terms_and_condition_2_accept_timestamp": new Date().getTime(),
        "close_account_confirm_date": self.state.close_account_confirm_date,
        "close_account_confirm_time": self.state.close_account_confirm_time,
        "close_account_confirm_timestamp": self.state.close_account_timestamp
      }


      axios
        .request({
          url: url.closeMyAccount,
          method: "POST",
          data: JSON.stringify(data_to_close_account),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
          }
        })
        .then(function (close_Account_Response) {

          if (close_Account_Response && close_Account_Response.data && close_Account_Response.data.success == 1) {
            self.setState({
              "account_close_link_sent": true,
              "response_message": close_Account_Response.data.message,
              "loader": false
            })
            setTimeout(() => {
              let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
              let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
              let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
              let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
              let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
              let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
              let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
              localStorage.clear();
              localStorage.setItem("perks_or_main", perks_or_main)
              localStorage.setItem("previous_state", previous_state)
              localStorage.setItem("current_state", current_state)
              localStorage.setItem("program", JSON.stringify(program))
              localStorage.setItem("api_auth", api_auth)
              localStorage.setItem("program_list", JSON.stringify(program_list))
              localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
              cookie.remove("auto_id", { path: "/" })
              cookie.remove("social_medium", { path: "/" })
              cookie.remove("social_shared_prog_id", { path: "/" })
              cookie.remove("social_shared_refered_id", { path: "/" })
              cookie.remove("winner_later", { path: "/" })
              cookie.remove("provider", { path: "/" })
              cookie.remove("facebook_id", { path: "/" })
              cookie.remove("google_id", { path: "/" })
              cookie.remove("tracking_id", { path: "/" })
              cookie.remove("username", { path: "/" })
              cookie.remove("accept_auto_id", { path: "/" })
              cookie.remove("relative_id", { path: "/" })
              cookie.remove("winner_id", { path: "/" })
              cookie.remove("winner_program_id", { path: "/" })
              cookie.remove("winner_claim_days", { path: "/" })
              cookie.remove("claim_status", { path: "/" })
              cookie.remove("email_changed", { path: "/" })
              cookie.remove("alternative_email", { path: "/" })
              self.props.history.push("/")
            }, 10000)
          } else {
            self.setState({
              "account_close_link_sent": false,
              "response_message": close_Account_Response.data.message,
              "loader": false,
              "error_found": true
            })
          }
        })
    }
    else{
      this.setState({ show_error_message: "Please type CLOSE in input field" })

  }

  }

  handleClose = () => {
    this.toggle()
    let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
    let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
    let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
    let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
    let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
    let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
    let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
    localStorage.clear();
    localStorage.setItem("perks_or_main", perks_or_main)
    localStorage.setItem("previous_state", previous_state)
    localStorage.setItem("current_state", current_state)
    localStorage.setItem("program", JSON.stringify(program))
    localStorage.setItem("api_auth", api_auth)
    localStorage.setItem("program_list", JSON.stringify(program_list))
    localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
    cookie.remove("auto_id", { path: "/" })
    cookie.remove("social_medium", { path: "/" })
    cookie.remove("social_shared_prog_id", { path: "/" })
    cookie.remove("social_shared_refered_id", { path: "/" })
    cookie.remove("winner_later", { path: "/" })
    cookie.remove("provider", { path: "/" })
    cookie.remove("facebook_id", { path: "/" })
    cookie.remove("google_id", { path: "/" })
    cookie.remove("tracking_id", { path: "/" })
    cookie.remove("username", { path: "/" })
    cookie.remove("accept_auto_id", { path: "/" })
    cookie.remove("relative_id", { path: "/" })
    cookie.remove("winner_id", { path: "/" })
    cookie.remove("winner_program_id", { path: "/" })
    cookie.remove("winner_claim_days", { path: "/" })
    cookie.remove("claim_status", { path: "/" })
    cookie.remove("email_changed", { path: "/" })
    cookie.remove("alternative_email", { path: "/" })
    let { history } = this.props;
    if (history) history.push("/");
  }

  handleCrossClose = () => {
    this.toggle()
    if (this.state.account_close_link_sent && this.state.account_close_link_sent == true) {
      let previous_state = localStorage.getItem("previous_state") ? localStorage.getItem("previous_state") : "";
      let current_state = localStorage.getItem("current_state") ? localStorage.getItem("current_state") : "";
      let perks_or_main = localStorage.getItem("perks_or_main") ? localStorage.getItem("perks_or_main") : "";
      let program = localStorage.getItem("program") ? JSON.parse(localStorage.getItem("program")) : "";
      let program_list = localStorage.getItem("program_list") ? JSON.parse(localStorage.getItem("program_list")) : "";
      let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
      let api_auth = localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : "";
      localStorage.clear();
      localStorage.setItem("perks_or_main", perks_or_main)
      localStorage.setItem("previous_state", previous_state)
      localStorage.setItem("current_state", current_state)
      localStorage.setItem("program", JSON.stringify(program))
      localStorage.setItem("api_auth", api_auth)
      localStorage.setItem("program_list", JSON.stringify(program_list))
      localStorage.setItem("dynamic_content", JSON.stringify(dynamic_content))
      cookie.remove("auto_id", { path: "/" })
      cookie.remove("social_medium", { path: "/" })
      cookie.remove("social_shared_prog_id", { path: "/" })
      cookie.remove("social_shared_refered_id", { path: "/" })
      cookie.remove("winner_later", { path: "/" })
      cookie.remove("provider", { path: "/" })
      cookie.remove("facebook_id", { path: "/" })
      cookie.remove("google_id", { path: "/" })
      cookie.remove("tracking_id", { path: "/" })
      cookie.remove("username", { path: "/" })
      cookie.remove("accept_auto_id", { path: "/" })
      cookie.remove("relative_id", { path: "/" })
      cookie.remove("winner_id", { path: "/" })
      cookie.remove("winner_program_id", { path: "/" })
      cookie.remove("winner_claim_days", { path: "/" })
      cookie.remove("claim_status", { path: "/" })
      cookie.remove("email_changed", { path: "/" })
      cookie.remove("alternative_email", { path: "/" })
      let { history } = this.props;
      if (history) history.push("/");
    }
  }


  componentWillMount() {
    let self = this
    let sub_id = cookie.load("auto_id") ? cookie.load("auto_id") : localStorage.getItem("auto_id")
    sub_id = sub_id ? sub_id : "null"
    let email = cookie.load("username") ? cookie.load("username") : localStorage.getItem("email")
    if (sub_id == "null") {
      this.props.history.push('/home');
    } else {
      if (email && email != "" && email != "null" && email != null && typeof email != undefined && sub_id && sub_id != "" && sub_id != "null" && sub_id != null && typeof sub_id != undefined) {
        let finaljson = {
          email: email,
          sub_id: sub_id
        }
        functions.checkUserExistence(self, finaljson, function (response1_json) {
          let response1 = JSON.parse(response1_json)

          if (response1.data && response1.data.dynamo && response1.data.dynamo.Items && response1.data.dynamo.Items.length == 0) {
            functions.pushToRoute(self, "", "help")
          } else {
            let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
            if (dynamic_content != "") {
              self.setState({ dynamic_content: dynamic_content })
            }

            let action = "User landed on help page"
            let image = 'help_page_landing.png'
            functions.logUserActivity(action, image);
            localStorage.setItem("active_button", "3")
            self.setState(
              {
                show_terms: localStorage.getItem('show_terms') == 'true' ? localStorage.getItem('show_terms') : false,
                show_support: localStorage.getItem('show_support') == 'true' ? localStorage.getItem('show_support') : false,
                loader:false
              }
            )
          }
        })
      } else {
        this.props.history.push("/")
      }
    }
  }

  editprofile() {
    let action = "User clicked on edit button on help page"
    let image = 'edit_button_help_page.png'
    functions.logUserActivity(action, image);
    this.props.history.push('/editprofile');
  }

  terms() {
    this.props.history.push('/terms');
  }

  toggleAddchild() {
    this.setState({
      modal: !this.state.modal
    });
  }

  handleState = (state_name) => {
    let action = "User clicked on " + state_name + " button on support page"
    let image = state_name + "button_support_page.png"
    functions.logUserActivity(action, image);
    this.setState({ [state_name]: true })
    if (state_name == 'show_support') {
      let action = "User landed on support page"
      let image = 'support_page_landing.png'
      functions.logUserActivity(action, image);
    } else if (state_name == 'show_terms') {
      let action = "User landed on support terms page"
      let image = 'support_terms_page_landing.png'
      functions.logUserActivity(action, image);
    }
    else if (state_name == 'show_account_status') {
      let action = "User landed on support terms page"
      let image = 'support_terms_page_landing.png'
      functions.logUserActivity(action, image);
    }
    localStorage.setItem([state_name], 'true');
  }

  handlePreviousPage = () => {
    let action = "User clicked on back button on help page"
    let image = 'back_button_help_page.png'
    functions.logUserActivity(action, image);
    this.setState({ show_terms: false })
    this.setState({ show_support: false })
    this.setState({ show_account_status: false })
    localStorage.removeItem('show_terms');
    localStorage.removeItem('show_support');
  }

  handleTermsClick = () => {
    let mydiv = document.getElementById("open_term")
    if (mydiv) {
      mydiv.click()
    }
  }

  render() {
    const { dynamic_content } = this.state
    return (
      <div>
        {this.state.loader &&
          <center className="loader width_auto">
              <LottieLoader className="loader_class_help" color="secondary" />
          </center>
        }
        {dynamic_content != "" &&
          <div className="container">
            {this.state.show_terms &&
              <TermsConditions props={this.props} onBack={this.handlePreviousPage} />
            }

            {this.state.show_support &&
              <Support props={this.props} onBack={this.handlePreviousPage} />
            }
            {this.state.show_account_status &&

              <>
                <div className="container">
                  <div className="row">
                    <div className="common_mainAddOn">
                   
                      {dynamic_content != "" &&
                        <div className="common_main">
                          <div className="sign_backlink sign_backlinkNw" onClick={this.handlePreviousPage} >
                            <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                          </div>
                          <div className="profileTp">
                                    <h1>Profile</h1>
                                    <h2>{dynamic_content.static_text.extra ? dynamic_content.static_text.extra.support.en : "Account"}</h2>
                                </div>
                          <div className="setting_mainInner">


                            <div className="acuntStatus">
                              {dynamic_content.static_text.notification_settings && dynamic_content.static_text.notification_setting.account_status ?
                                <label>{dynamic_content.static_text.notification_setting.account_status.en}</label> :
                                <label>Account Status</label>
                              }
                              <div className="settingActvAc">
                                <a>Active</a>
                              </div>
                            </div>

                            <div className="acuntSetting">
                              <div className="settingClsAc">
                                <a onClick={() => { this.setState({ open_dailog: true }) }}>Close my account </a>
                                <p>and purge all personally identifiable information</p>
                              </div>
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  </div>
                </div>





                <div className="open_mail_model">
                  <Modal isOpen={this.state.open_dailog} toggle={this.toggle} className={this.props.className + " custom_Addchildmodel"} backdrop={'static'} keyboard={false}>
                    <ModalBody className="ntcBody">
                      <div className="ntvcontent">

                        <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.handleCrossClose() }} className="ntcimgClose" />

                        {this.state.second_modal == false &&
                          <div className="ntc_head">
                            {/* <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.multiple.en : "Multiples!!!"}</h3> */}
                            <h3>Notice!</h3>
                            <div className="notice_warning1">
                              <p>I hereby request my account be closed and all of my personally identifiable information be purged.
                              </p>
                            </div>

                            <div className="ntc_headInr noticAgreeInput">
                              {/* <p>
                                                            <input
                                                                name="ischecked"
                                                                type="checkbox"
                                                                checked={this.state.ischecked}
                                                                onChange={(event) => { this.handleInputChange(event, 'ischecked') }}
                                                            />
                                                        </p> */}

                              <div>
                                <input
                                  className="inp-cbx"
                                  id="noticAgree"
                                  style={{ display: 'none' }}
                                  name="ischecked"
                                  type="checkbox"
                                  checked={this.state.ischecked}
                                  onChange={(event) => { this.handleInputChange(event, 'ischecked') }}
                                />
                                <label className="cbx" htmlFor="noticAgree">
                                  <span>
                                    <svg width="12px" height="9px" viewBox="0 0 12 9">
                                      <polyline points="1 5 4 8 11 1"></polyline>
                                    </svg>
                                  </span>
                                </label>
                              </div>
                              <p>{this.state.close_my_account_tnc1}</p>
                            </div>

                            {/* <div className="ntcUpdateMultiple"><Button onClick={() => { this.handleAgree() }} size="medium" color="primary">{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_multiples.en : "Add multiples"}</Button></div> */}
                            <div className="ntcUpdate"><Button onClick={() => { this.handleConfirm() }} size="medium" color="primary">Continue</Button></div>
                          </div>
                        }
                        {this.state.second_modal == true && !this.state.account_close_link_sent &&
                          <div className="ntc_head">
                            {/* <h3>{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.multiple.en : "Multiples!!!"}</h3> */}
                            <h3>Notice!</h3>
                            <div className="notice_warning1">
                              <p>
                                Close My Account
                              </p>
                            </div>
                            <div className="ntc_headInr" style={{ marginBottom: "10px" }}>
                              <p>
                                {/* <input
                                                                name="ischeckedsecond"
                                                                type="checkbox"
                                                                checked={this.state.ischeckedsecond}
                                                                onChange={(event) => { this.handleInputChange(event, 'ischeckedsecond') }}
                                                            /> */}
                                {this.state.close_my_account_tnc2}
                              </p>
                            </div>
                            <div className="notice_warning1 notice_warningcstm">
                              <p>
                                To affirm your consent, type <span>CLOSE</span> in the box below:
                              </p>
                              <input
                                placeholder='Type'
                                className="notice_warning_text"
                                name="confirm"
                                type="text"
                                value={this.state.confirm}
                                onChange={(event) => { this.setState({ confirm: event.target.value, close_account_confirm_date: new Date().toLocaleDateString(), close_account_confirm_time: new Date().toLocaleTimeString(), close_account_confirm_timestamp: new Date().getTime(), error_found: false, response_message: "", show_error_message: "" }) }}
                              />
                            </div>
                            {/* <div className="ntcUpdateMultiple"><Button onClick={() => { this.handleAgree() }} size="medium" color="primary">{dynamic_content.static_text.addchild ? dynamic_content.static_text.addchild.add_multiples.en : "Add multiples"}</Button></div> */}
                            <div className="ntcUpdate"><Button onClick={() => { this.handleDone() }} size="medium" color="primary">DONE</Button></div>
                          </div>
                        }

                        {
                          this.state.account_close_link_sent == true &&
                          <div className="account_close_link_sent ntc_head closeEmailAc">
                            <h3>Check Your Email</h3>
                            <div className='closeEmailAcInr'>
                              <label>Close My Account</label>
                              <h4>We have sent you an email.</h4>
                              <div><img src={require('../../assets/images/emailSent.svg')} alt="" title="" /></div>
                              <p>Please follow the instructions in the email to complete your account closure request. The email will only be valid for 30 minutes.</p>
                            </div>
                            <div className="ntcUpdate">
                              <Button onClick={() => { this.handleClose() }} size="medium" color="primary">Close</Button>
                            </div>
                          </div>
                        }

                        {
                          !this.state.loader && this.state.error_found == true && this.state.response_message && this.state.response_message.trim().length > 0 &&
                          <div className={"response_error"}>
                            {this.state.response_message}
                          </div>
                        }

{this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }

                                            {this.state.loader &&
                                                <center>
                                                    <LottieLoader className="loader_class" color="secondary" />
                                                </center>
                                            }
                      </div>
                    </ModalBody>
                  </Modal>
                </div>
              </>

            }
            {!(this.state.show_terms || this.state.show_support || this.state.show_account_status) &&
              <div className="row">
                <div className="common_mainAddOn">
                  <div className="common_main">

                    <div className="sign_backlink sign_backlinkNw" onClick={() => { functions.pushToRoute(this, "profile", 'setting') }}>
                      <img src={require('../../assets/images/bkArorIcon.svg')} alt="" title="" />
                    </div>

                    <div className="profileTp">
                      {/* <h1 >{dynamic_content.static_text.help ? dynamic_content.static_text.help.help.en : "Help"}</h1> */}
                      <h1>Profile</h1>
                      <h2>Help/Support</h2>
                    </div>

                    <div className="help_content" onClick={() => { this.handleState('show_support') }}>
                      <div className="help_contentLeft" >
                        <img src={require('../../assets/images/supportNew.svg')} alt="" title="" /><span>{dynamic_content.static_text.help ? dynamic_content.static_text.help.support.en : "Support"}</span>
                      </div>
                      <div className="help_contentRght">
                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                      </div>
                    </div>
                    <a target="_blank" href="https://www.iubenda.com/privacy-policy/7780464" id="open_term" />
                    <div className="help_content" onClick={() => { this.handleTermsClick() }}>
                      <div className="help_contentLeft" >
                        <img src={require('../../assets/images/tdNew.svg')} alt="" title="" /><span>{dynamic_content.static_text.help ? dynamic_content.static_text.help.terms_and_conditions.en : "Terms and Conditions"}</span>
                      </div>
                      <div className="help_contentRght">
                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                      </div>
                    </div>
                    <div className="help_content" onClick={() => { this.handleState('show_account_status') }}>
                      <div className="help_contentLeft" >
                        {/* <img src={require('../../assets/images/supportNew.svg')} alt="" title="" /><span>{dynamic_content.static_text.help ? dynamic_content.static_text.help.support.en : "Support"}</span> */}
                        <div>
    <img src={require('../../assets/images/cat4Active.svg')} alt="" title="" />
    <span>Account Status</span>
</div>


                      </div>
                      <div className="help_contentRght">
                        <a><img src={require('../../assets/images/arowRght.svg')} alt="" title="" /></a>
                      </div>
                    </div>

                    {/* faqs */}
                    <div className="faq_label"><h2>{dynamic_content.static_text.help ? dynamic_content.static_text.help.faq.en : "FAQ"}</h2></div>

                    <div className="custom_faqs_main">

                      <div className="custom_faqs">
                        <Collapsible trigger="What's the difference between 32-bit and 64-bit versions of Windows?">
                          <div className="faqs_ans">
                            <p>{dynamic_content.static_text.help ? dynamic_content.static_text.help.faq_ans1.en : ""}</p>
                          </div>
                        </Collapsible>
                      </div>

                      <div className="custom_faqs">
                        <Collapsible trigger="What's the difference between 32-bit and 64-bit versions of Windows?">
                          <div className="faqs_ans">
                            <p>{dynamic_content.static_text.help ? dynamic_content.static_text.help.faq_ans2.en : ""}</p>
                          </div>
                        </Collapsible>
                      </div>
                    </div>
                    {/* faqs */}

                    {/* Site menu */}
                    <Footer props={this.props} />
                    {/*End of Site menu */}

                    {/* Add another child model */}
                    <div className="open_mail_model">
                      <Modal isOpen={this.state.modal} className="msg_dtlmodel">
                        <ModalBody className="msg_dltBody">
                          <div className="msg_model">
                            <div className="msg_dlt_content">
                              <div className="msg_mail">
                                <a>help@familyone.com</a>
                              </div>
                              <div>
                                <Button size="medium" color="primary" onClick={this.editprofile}>
                                  {dynamic_content.static_text.help ? dynamic_content.static_text.help.send_email.en : "Send Email"}
                                </Button>
                              </div>
                            </div>
                          </div>
                        </ModalBody>
                      </Modal>
                    </div>

                    {/* Add another child model */}

                  </div>
                </div>
              </div>
            }
          </div>
        }</div>
    );
  }
}

export default Help;