import React, { Fragment, useState } from 'react';
import '../../assets/css/style.css';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import cookie from 'react-cookies';
import LottieLoader from '../LottieLoader';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import uuid from 'react-uuid'
import ReactHtmlParser from "react-html-parser";
export default class EntercstConsent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            policyadvisor_link: "",
            policyadvisor_id: uuid(),
            dynamic_content: ""
        }
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }
        let { policyadvisor_id } = this.state
        let policyadvisor_link = "https://api.fintelconnect.com/t/l/finteltag/a-28153b-25385c-" + policyadvisor_id
        this.setState({ policyadvisor_link: policyadvisor_link })
    }

    handleSubmit = (consent) => {
        let self = this
        this.handleAnimation();
        localStorage.setItem("cst_consent_required", "false")
        localStorage.setItem("show_cst_consent", "false");
        if(consent =="no") setTimeout(() => {
            self.props.onSubmit(undefined, consent)
        }, 500);
        else if(consent =="yes"){
            self.props.contestEntryCST(consent)
        }

    }

    handleAnimation = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 1 }, { opacity: 0, height: '200px' })
            .to('.program_entry_loader', 0.5, { opacity: 1 })
    }

    removeAnimationEffect = () => {
        let TL = new TimelineMax();
        TL.fromTo('.phone_verification_block', 0.5, { opacity: 0 }, { opacity: 1, height: 'auto' })
            .to('.program_entry_loader', 0.5, { opacity: 0 })
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="">
                {dynamic_content != "" &&
                    <div className="row rel_block fade_it_up">
                        <div className="phone_verification_block" style={{ position: "relative" }}>
                            <div className="phone_verify_backlink"><img onClick={() => { this.props.Back() }} src={url.family_one_image_url + 'Group147.png'} alt="" title="" /></div>
                            <div className="contest_up">
                                <h1 style={{"display":"table"}}> </h1>
                            </div>
                            <img src={require('../../assets/images/closeChild.svg')} alt="" title="" onClick={() => { this.handleSubmit('no') }} className="clearcontest_img popup_close_button cst_close" />
                            <div className='cst_message'>
                                {this.props.props.program && this.props.props.program.cst_consent_message ? ReactHtmlParser(this.props.props.program.cst_consent_message) :<p>By clicking “Yes” you agree for  family.one to transfer relevant information and contact details as referenced in the Terms and Conditions to CST Saving Inc. and its CST affiliates to contact you by phone or email for you to learn more about the importance of saving for your child’s post-secondary education. You may withdraw your consent at anytime. For more details see the linked  Terms and Conditions, Privacy Policy or Contact CST Saving Inc.</p>}
                            </div>
                            {this.state.show_error_message && this.state.show_error_message != "" &&
                                <p className="error_message">
                                    {this.state.show_error_message}
                                </p>
                            }
                            <div className="contest_msg">
                                <div className="contest_opt_policy_advisor">
                                    {/* <a > */}
                                    <Button size="medium" color="primary" onClick={() => { this.handleSubmit('yes') }}>
                                        {dynamic_content.static_text.cstconsent ? dynamic_content.static_text.cstconsent.yes.en : "Yes"}
                                    </Button>
                                    
                                </div>
                            </div>

                        </div>
                        <div className="program_entry_loader">
                            <LottieLoader />
                        </div>

                    </div>
                }
            </div>
        );
    }
}