import React from 'react';
import '../../assets/css/style.css';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import functions from '../../assets/functions/common_functions';
import url from '../../assets/url';
import LottieLoader from '../LottieLoader';
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2
} from "react-html-parser";
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { TimelineMax, Bounce, Linear, Power1, Elastic, Back, Sine } from "gsap/all";
import CSSPlugin from 'gsap/CSSPlugin';
import cookie from 'react-cookies';
import { Form, FormGroup, Label, Input, FormText } from 'reactstrap';
import VideoThumbnail from 'react-video-thumbnail';
// import Files from "react-butterfiles";
import RFiles from 'react-files'
import { async } from 'q';
import $ from "jquery";
// const Cryptr = require('cryptr');
const createWTClient = require('@wetransfer/js-sdk');
// const cryptr = new Cryptr('Family.one');

/**
 * Retrieve pre-signed POST data from a dedicated API endpoint.
 * @param selectedFile
 * @returns {Promise<any>}
 */
const C = CSSPlugin;


/**
 * Upload file to S3 with previously received pre-signed POST data.
 * @param presignedPostData
 * @param file
 * @returns {Promise<any>}
 */

class uploadimg extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            description: "",
            review_request_id: localStorage.getItem("review_request_id"),
            request_timestmp: parseInt(localStorage.getItem("review_timestamp")),
            media: [],
            files: [],
            preview_media: [],
            show_error_message: "",
            review_upload_status: false,
            loader: false,
            show_upload_loader: false,
            show_preview_media: false,
            loaded_percentage: 0,
            key: '',
            url_array: [],
            dynamic_content: ""
        };
        this.confirm = this.confirm.bind(this);
    }

    componentWillMount = () => {
        let dynamic_content = localStorage.getItem("dynamic_content") ? JSON.parse(localStorage.getItem("dynamic_content")) : "";
        if (dynamic_content != "") {
            this.setState({ dynamic_content: dynamic_content })
        }

        let success_action = "User landed on uploadimg page"
        let image = 'uploadimg_page_landing.png'
        functions.logUserActivity(success_action, image);
        this.getWeTransferKey();
        let self = this;
        let program_id = localStorage.getItem("program_id")
        // Set program related info
        let program = JSON.parse(localStorage.getItem("program"))
        
        let is_direct = 0;
        let program_array = []
        program_array.push(program)
        localStorage.setItem("review_logo_image", program.program_image);
        this.setState({ program_array: program_array, program_type: program.program_type })
    }

    confirm = () => {
        let success_action = "User clicked on upload and save on uploadimg page"
        let image = 'upload_and_save_button_uploadimg_page.png'
        functions.logUserActivity(success_action, image);
        let self = this;
        let is_winner_review = localStorage.getItem("winner_review") && localStorage.getItem("winner_review") == "true" ? true : false;
        if (this.state.description && this.state.description != "" && this.state.media.length > 0) {
            this.setState({
                show_error_message: "",
                loader: true
            })
            this.handleFileUplaod().then(() => {
                // this.setState({
                //     show_error_message: "",
                //     loader: true
                // })
                let review_request_id = this.state.review_request_id,
                    request_timestmp = this.state.request_timestmp,
                    description = this.state.description,
                    review_media = this.state.url_array;

                self.sendFileUploadMail();
                if (is_winner_review) {
                    let program_id = localStorage.getItem('program_id');
                    let sub_id = cookie.load('auto_id');

                    functions.updateWinnerReview(this, sub_id, program_id, description, review_media, function (response_json) {
                        let response = JSON.parse(response_json);
                        if (response.error == 1) {
                        }
                        else {
                            self.setState({
                                show_error_message: "",
                                loader: false
                            })

                        }
                    })
                }
                else {
                    functions.update_review(this, review_request_id, request_timestmp, description, review_media, function (response_json) {
                        let response = JSON.parse(response_json);
                        if (response.data.success == 1) {
                            self.setState({
                                show_error_message: "",
                                loader: false
                            })
                        }
                    });
                }

            })

            if (is_winner_review) {
                localStorage.setItem("detail_page", 'false')
            }
            else {
                let program_id = localStorage.getItem("program_id")
                localStorage.setItem("review_complete", program_id)
                localStorage.setItem("detail_page", 'true')
            }
            functions.pushToRoute(self, 'main', 'uploadimg')

        } else {
            if (this.state.description == "") {
                let user_action = "User got error on review_section/uploadimg file confirm function";
                let body = url.dynamic_content.error_message.enter_description.en
                functions.logUserError(user_action, body);
                this.setState({
                    show_error_message: url.dynamic_content.error_message.enter_description.en
                })
            }
            if (this.state.media.length == 0) {
                let user_action = "User got error on review_section/uploadimg file confirm function";
                let body = url.dynamic_content.error_message.upload_media.en
                functions.logUserError(user_action, body);
                this.setState({
                    show_error_message: url.dynamic_content.error_message.upload_media.en
                })
            }
        }
    }

    sendFileUploadMail = () => {
        let dynamic_content = this.state.dynamic_content
        let file_links = this.state.url_array.join("\r\n");
        let email = 'support@webnexus.in'
        let object_to_replace = {
            '%FILE_LINK%': file_links
        }
        let subject = dynamic_content.static_text && dynamic_content.static_text.uploadimg && dynamic_content.static_text.uploadimg.mail_subject ? this.mutliStringReplace(object_to_replace, dynamic_content.static_text.uploadimg.mail_subject.en) : 'Review File Uploaded';
        let text_message = ''
        let html_message = dynamic_content.static_text && dynamic_content.static_text.uploadimg && dynamic_content.static_text.uploadimg.html_message ? this.mutliStringReplace(object_to_replace, dynamic_content.static_text.uploadimg.html_message.en) : `<p>Valid only for 7 days<br/>Review Media URLs <br>` + file_links + `</p>`
        let category = 'Review File Upload';
        functions.sendSMTPMail(this, email, subject, text_message, html_message, category, function (response_json) {
            
        })
    }

    mutliStringReplace = (object, string) => {
        var val = string
        var entries = Object.entries(object);
        entries.forEach((para) => {
            var find = para[0]
            var regExp = new RegExp(find, 'g')
            val = val.replace(regExp, para[1])
        })
        return val;
    }

    handleClose = (index) => {
        let success_action = "User clicked on close button on uploadimg page"
        let image = 'close_button_uploadimg_page.png'
        functions.logUserActivity(success_action, image);
        let media = this.state.media;
        
        var removed = media.splice(index, 1);
        this.setState({ media: media })
    }

    onFilesChange = async (file_details) => {
        let self = this
        let media = self.state.media;
        let media_index = self.state.media.length > 0 ? self.state.media.length : 0
        // this.setState({ show_preview_media: true });
        this.setState({ ["preview_loader_" + media_index]: true })

        const fileList = file_details;
        

        var result = Object.keys(fileList).map(function (key) {
            return fileList[key];
        });
        let files = [];

        result.forEach((file, index1) => {
            media.push(file);
            if (result.length - 1 == index1) {
                self.setState({ media: media })
                // this.setState({ show_preview_media: false, preview_media: [] })
            }
            files.push({
                name: file.name,
                size: file.size,
                content: file
            });
        });


        file_details.forEach((file, index) => {
            try {
                

                setTimeout(() => {
                    self.setState({ ["preview_loader_" + media_index]: false })
                    self.fileInput.state.files = []
                    self.setState({ files: files })
                }, 1500);
            } catch (e) {
                
            }
        })
    }

    UploadAnimation = () => {
        let T1 = new TimelineMax();
    }

    handleCross = () => {
        let success_action = "User clicked on close button on uploadimg page"
        let image = 'close_button_uploadimg_page.png'
        functions.logUserActivity(success_action, image);
        let is_winner_review = localStorage.getItem("previous_state");
        if (is_winner_review == "prize_detail") {
            functions.pushToRoute(this, 'prize_detail', 'upload_review')
        } else if (is_winner_review == "profile") {
            functions.pushToRoute(this, 'profile', 'upload_review')
        } else {
            localStorage.removeItem("review_logo_image");
            localStorage.setItem("detail_page", 'true');
            functions.pushToRoute(this, "main", 'uploadimg')
        }
    }

    getWeTransferKey = () => {
        let self = this;
        functions.getwetransferkey(this, cookie.load("auto_id"), function (response_json) {
            let response = JSON.parse(response_json)
            if (response.data.success == 1) {
                // const decryptedString = cryptr.decrypt("a856996c28fa3e483c01b5d91119f0d47d3195c88e8b4a7e9f60f3e6c54c8fd9204783d21c45a5256b42593ba9a0009e86040678ccee06cc56ff3450c3075e4665f44613253f404cdc7f63899e41ffff4ffde25c89782bd0c4b4a9e6a4441e70b135dcafa69d97b46ad1eaf78a44b357accb9de8ffb1058c7c7da39939b494eb86c4c93cbd4f25f6");
                // const encryptedString = cryptr.encrypt(decryptedString);
                // let decryptedString = cryptr.decrypt(response.data.api_key);


                self.setState({ key: response.data.api_key });
            }
            else {
                let user_action = "User got error on review_section/uploadimg file in getWeTransferKey function";
                let error_body = response
                functions.logUserError(user_action, error_body);
            }
        });
    }

    handleFileUplaod = async () => {
        
        const wtClient = await createWTClient(this.state.key);
        
        let files = this.state.files;
        const transfer = await wtClient.transfer.create({
            message: 'uploaded file/files',
            files: files
        });
        if (transfer.url) {

            let url_array = this.state.url_array;
            url_array.push(transfer.url)
            this.setState({ url_array: url_array })
        }
        else {
            let user_action = "User got error on review_section/uploadimg file in handleFileUplaod function";
            let error_body = "transfer url is empty"
            functions.logUserError(user_action, error_body);
        }
    }

    render() {
        const { dynamic_content } = this.state
        return (
            <div className="container">
                <div className="row">
                  <div className="common_mainAddOn">
                    {dynamic_content != "" && this.state.program_array && this.state.program_array.length > 0 && this.state.program_array.map((program, index) => (
                        <div key={index} className="common_main upload_review">
                            {this.state.loader &&
                                <center>
                                    <LottieLoader className="loader_class" />
                                </center>
                            }
                            {/* common header */}
                            {!this.state.loader &&
                                <div className="moisback_section">
                                    <div className="moisback_secone">
                                        <div className="backLinkWidth"><img style={{ width: "50px",height:"50px" }} src={url.cloudflare_image_path + localStorage.getItem("review_logo_image")+url.varient} alt="" title="" /></div></div>
                                    <div className="moisback_sectwo"><label>{ReactHtmlParser(program.prize_name)}</label></div>
                                    <div onClick={() => { this.handleCross() }} className="moisback_secthree"><img src={url.family_one_image_url + 'Group5.png'} alt="" title="" /></div>
                                </div>
                            }
                            {/*End of common header */}
                            {!this.state.loader &&
                                <div>
                                    <div className="upload_main">
                                        <div className="upload_all">
                                            {this.state.media && this.state.media.length > 0 && this.state.media.map((element, index) => (
                                                <div key={index} className="upload_inner">
                                                    {element.type.slice(0, 5) == 'image' &&
                                                        <div className="upload_in">
                                                            {!this.state["preview_loader_" + index] &&
                                                                <div className="upload_cntnt">
                                                                    {/* <span className="helper"></span> */}
                                                                    <img src={element.preview.url} alt="" title="" className="min_h132 fadein_effect" />
                                                                </div>
                                                            }
                                                            {!this.state["preview_loader_" + index] &&
                                                                <a onClick={() => { this.handleClose(index) }}><img src={url.family_one_image_url + 'close172.png'} alt="" title="" /></a>
                                                            }
                                                            {this.state["preview_loader_" + index] &&
                                                                <div className="upload_cntnt">
                                                                    <img alt="" title="" className="min_h132 fadein_effect" />
                                                                </div>
                                                            }
                                                            {this.state["preview_loader_" + index] &&
                                                                <LottieLoader className="makeitcenter" />
                                                            }
                                                        </div>
                                                    }
                                                    {element.type.slice(0, 5) != 'image' &&
                                                        <div className="upload_in fadein_effect">
                                                            <VideoThumbnail

                                                                // videoUrl={image_url + element.name}
                                                                width={610}
                                                                height={400}
                                                                renderThumbnail={true}
                                                            />
                                                            <label>
                                                                {this.state["preview_loader_" + index] &&
                                                                    <img alt="" title="" />
                                                                }
                                                                {!this.state["preview_loader_" + index] &&
                                                                    <img src={url.family_one_image_url + 'play.png'} alt="" title="" />
                                                                }
                                                            </label>
                                                            {!this.state["preview_loader_" + index] &&
                                                                <a onClick={() => { this.handleClose(index) }}><img src={url.family_one_image_url + 'close172.png'} alt="" title="" /></a>
                                                            }
                                                            {this.state["preview_loader_" + index] &&
                                                                <img alt="" title="" />
                                                            }
                                                            {this.state["preview_loader_" + index] &&
                                                                <LottieLoader className="makeitcenter" />
                                                            }
                                                            {/* <img src={element.preview_url}  alt="" title="" className="min_h132 fadein_effect" /> */}
                                                        </div>
                                                    }
                                                </div>
                                            ))}

                                            {/* <div className="upload_inner">
                                        
                                    </div> */}

                                            {this.state.show_upload_loader &&
                                                <div className="upload_inner">
                                                    <div className="upload_in">
                                                        <img src="" alt="" title="" />
                                                        <a><img src={url.family_one_image_url + 'close172.png'} alt="" title="" /></a>
                                                        <CircularProgressbar className="makeitcenter" value={this.state.loaded_percentage} text={`${this.state.loaded_percentage}%`} />;
                                                    </div>
                                                </div>
                                            }

                                            <div className="upload_inner">
                                                <div className="upload_in uploadCaptureInputFile">
                                                    <RFiles
                                                        className='files-dropzone'
                                                        id="files-input"
                                                        onChange={this.onFilesChange}
                                                        onError={this.onFilesError}
                                                        accepts={['image/*', 'video/*']}
                                                        multiple
                                                        maxFiles={10}
                                                        maxFileSize={200000000}
                                                        minFileSize={0}
                                                        clickable
                                                        ref={ref => this.fileInput = ref}
                                                    >
                                                        <label className="select_files">+ {dynamic_content.static_text.uploadimg ? dynamic_content.static_text.uploadimg.upload.en : "Upload"}</label>
                                                    </RFiles>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="upload_form">
                                        <TextField
                                            autoComplete='off'
                                            label={dynamic_content.static_text.uploadimg ? dynamic_content.static_text.uploadimg.description.en : "Description"}
                                            type="text"
                                            margin="normal"
                                            className="upload_first"
                                            multiline="true"
                                            rowsmax="4"
                                            value={this.state.description}
                                            onChange={(e) => { this.setState({ description: e.target.value }) }}
                                        />

                                        {this.state.show_error_message && this.state.show_error_message != "" &&
                                            <p className="error_message">
                                                {this.state.show_error_message}
                                            </p>
                                        }
                                        <div className="rel_block ht-200">
                                            <div className="program_entry_loader">
                                                <LottieLoader className="loading" />
                                            </div>
                                        </div>
                                        <div className="upload_submit">
                                            <Button size="medium" color="primary" onClick={() => { this.confirm() }} >
                                                {dynamic_content.static_text.uploadimg ? dynamic_content.static_text.uploadimg.upload_and_save.en : "Upload and Save"}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    ))}
                  </div>  
                </div>
            </div >
        );
    }
}

export default uploadimg;
