import axios from "axios";
import url from '../url';
import functions from './common_functions'
import cookie from "react-cookies";
var base64 = require('base-64');
var session = {

  get_browser_info() {
    var ua = navigator.userAgent, tem, M = ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
    if (/trident/i.test(M[1])) {
      tem = /\brv[ :]+(\d+)/g.exec(ua) || [];
      return { name: 'IE ', version: (tem[1] || '') };
    }
    if (M[1] === 'Chrome') {
      tem = ua.match(/\bOPR\/(\d+)/)
      if (tem != null) { return { name: 'Opera', version: tem[1] }; }
    }
    M = M[2] ? [M[1], M[2]] : [navigator.appName, navigator.appVersion, '-?'];
    if ((tem = ua.match(/version\/(\d+)/i)) != null) { M.splice(1, 1, tem[1]); }
    return {
      name: M[0],
      version: M[1]
    };
  },

  //To handle session of the users
  checkSession(auto_id, session_type, insert_data) {
    try{
      setTimeout(() => {
        let addressType = localStorage.getItem("addressType");
        if (!addressType && auto_id) {
          functions.getUserAddressType({ sub_id: auto_id });
        }
      }, 0);
      let self = this
      let browser_info = {};
      let browser = this.get_browser_info();
      browser_info.Browser_CodeName = window.navigator.appCodeName == "" ? " " : window.navigator.appCodeName;
      browser_info.Browser_Name = browser.name == "" ? " " : browser.name;
      browser_info.Browser_Version = browser.version == "" ? " " : browser.version;
      browser_info.Cookies_Enabled = window.navigator.cookieEnabled == "" ? " " : window.navigator.cookieEnabled;
      browser_info.Platform = window.navigator.platform == "" ? " " : window.navigator.platform;
      browser_info.User_agent_header = window.navigator.userAgent == "" ? " " : window.navigator.userAgent;
      browser_info.Vendor = window.navigator.vendor == "" ? " " : window.navigator.vendor
      let session_id;
      if (typeof cookie.load("session_id") !== undefined &&
        cookie.load("session_id") !== "undefined" &&
        cookie.load("session_id") !== "null" &&
        cookie.load("session_id") !== null &&
        cookie.load("session_id")) { session_id = cookie.load("session_id") }
      else {
        session_id = "null";
      }
      let temp_session_type = "null"
      if (auto_id && auto_id != null && auto_id != "" && auto_id != "null" && auto_id != "undefined" && typeof auto_id != undefined) {
        temp_session_type = "session_already_exist"
      }
      let http_referer = "null"
      if (typeof cookie.load("http_referer") !== undefined &&
        cookie.load("http_referer") !== "undefined" &&
        cookie.load("http_referer") !== "null" &&
        cookie.load("http_referer") !== null &&
        cookie.load("http_referer")) { http_referer = cookie.load("http_referer") }
  
      http_referer = http_referer && http_referer != "null" ? http_referer : document.referrer
      cookie.save("http_referer", http_referer, { path: "/" });

      let session_create = {
        session_id: session_id,
        referred_by_id: 'null',
        http_headerinfo: browser_info,
        http_referrer: http_referer ? http_referer : "null",
        sub_id: auto_id ? auto_id : "null",
        refer_type: "null",
        session_type: session_type && session_type != "" ? session_type : temp_session_type,
        insert_data: insert_data
      }
  

      axios
        .request({
          url: url.session_manage,
          method: "POST",
          data: JSON.stringify(session_create),
          headers: {
            "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
          }
        })
        .then(function (response) {

          if (response.data.failed && response.data.failed == 1) {
            functions.handleErrorPopup()
          }
          let encoded = base64.encode(response.data.session_id)
          localStorage.setItem("api_auth", encoded)
          cookie.save("session_id", response.data.session_id, { path: "/" });
          if (response.data.session_auto_id) {
            cookie.save("session_auto_id", response.data.session_auto_id, { path: "/" });
          }
          let last_visited_page = localStorage.getItem("current_state")
          self.updatePageSession(response.data.session_id, last_visited_page)
          // self.createEvent("home_page_load");
        })
        .catch(function (error) {

        })
    } catch (error){

    }
    
  },

  createSession(auto_id, session_type, callback) {
    try{
      
    setTimeout(() => {
      let addressType = localStorage.getItem("addressType");
      if (!addressType && auto_id) {
        functions.getUserAddressType({ sub_id: auto_id });
      }
    }, 0);
    let self = this
    let browser_info = {};
    let browser = this.get_browser_info();
    browser_info.Browser_CodeName = window.navigator.appCodeName;
    browser_info.Browser_Name = browser.name;
    browser_info.Browser_Version = browser.version;
    browser_info.Cookies_Enabled = window.navigator.cookieEnabled;
    browser_info.Platform = window.navigator.platform;
    browser_info.User_agent_header = window.navigator.userAgent;
    browser_info.Vendor = navigator.vendor
    let session_id;
    if (typeof cookie.load("session_id") !== undefined &&
      cookie.load("session_id") !== "undefined" &&
      cookie.load("session_id") !== "null" &&
      cookie.load("session_id") !== null &&
      cookie.load("session_id")) { session_id = cookie.load("session_id") }
    else {
      session_id = null;
    }

    let http_referer = "null"
    if (typeof cookie.load("http_referer") !== undefined &&
      cookie.load("http_referer") !== "undefined" &&
      cookie.load("http_referer") !== "null" &&
      cookie.load("http_referer") !== null &&
      cookie.load("http_referer")) { http_referer = cookie.load("http_referer") }

    http_referer = http_referer && http_referer != "null" ? http_referer : document.referrer
    cookie.save("http_referer", http_referer, { path: "/" });
    let session_create = {
      session_id: session_id,
      referred_by_id: 'null',
      http_headerinfo: browser_info,
      http_referrer: http_referer ? http_referer : "null",
      sub_id: auto_id ? auto_id : "null",
      refer_type: "null",
      session_type: session_type ? session_type : "null",
      insert_data: 'true'
    }
    axios
      .request({
        url: url.session_manage,
        method: "POST",
        data: JSON.stringify(session_create)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          functions.handleErrorPopup()
        }
        let encoded = base64.encode(response.data.session_id)
        localStorage.setItem("api_auth", encoded)
        cookie.save("session_id", response.data.session_id, { path: "/" });
        if (response.data.session_auto_id) {
          cookie.save("session_auto_id", response.data.session_auto_id, { path: "/" });
        }
        callback(JSON.stringify(response));
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
        callback(JSON.stringify(error_json))
      });
    } catch (error){

    }
  },

  updateSession(session_id, session_auto_id, auto_id, tracking_id, contest_id) {
    try{
      setTimeout(() => {
        let addressType = localStorage.getItem("addressType");
        if (!addressType && auto_id) {
          functions.getUserAddressType({ sub_id: auto_id });
        }
      }, 0);
      let self = this
      if (session_id == null) {
        let browser_info = {};
        let browser = this.get_browser_info();
        browser_info.Browser_CodeName = window.navigator.appCodeName;
        browser_info.Browser_Name = browser.name;
        browser_info.Browser_Version = browser.version;
        browser_info.Cookies_Enabled = window.navigator.cookieEnabled;
        browser_info.Platform = window.navigator.platform;
        browser_info.User_agent_header = window.navigator.userAgent;
        browser_info.Vendor = navigator.vendor
        let session_id;
        if (typeof cookie.load("session_id") !== undefined &&
          cookie.load("session_id") !== "undefined" &&
          cookie.load("session_id") !== "null" &&
          cookie.load("session_id") !== null &&
          cookie.load("session_id")) { session_id = cookie.load("session_id") }
        else {
          session_id = null;
        }
        let http_referer = "null"
        if (typeof cookie.load("http_referer") !== undefined &&
          cookie.load("http_referer") !== "undefined" &&
          cookie.load("http_referer") !== "null" &&
          cookie.load("http_referer") !== null &&
          cookie.load("http_referer")) { http_referer = cookie.load("http_referer") }

        http_referer = http_referer && http_referer != "null" ? http_referer : document.referrer
        cookie.save("http_referer", http_referer, { path: "/" });
        let session_create = {
          session_id: session_id,
          referred_by_id: 'null',
          http_headerinfo: browser_info,
          http_referrer: http_referer ? http_referer : "null",
          sub_id: auto_id ? auto_id : "null",
          refer_type: "null",
          session_type: "null",
          insert_data: 'false'
        }




        axios
          .request({
            url: url.session_manage,
            method: "POST",
            data: JSON.stringify(session_create),
            headers: {
              "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
            }
          })
          .then(function (response) {
            if (response.data.failed && response.data.failed == 1) {
              functions.handleErrorPopup()
            }
            cookie.save("session_id", response.data.session_id, { path: "/" });
            let session_update = {
              session_id: session_id,
              session_auto_id: session_auto_id,
              tracking_id: tracking_id ? tracking_id : "",
              contest_id: contest_id ? contest_id : ""
            }
            self.update_axios(session_update)
          })
          .catch(function (error) {

          })
      }
      else {
        let session_update = {
          session_id: session_id,
          session_auto_id: session_auto_id,
          tracking_id: tracking_id ? tracking_id : "",
          contest_id: contest_id ? contest_id : ""
        }

        this.update_axios(session_update)

      }
    } catch (error){

    }
  },

  updatePageSession(session_id, last_visited_page) {
    let session_update = {
      session_id: session_id,
      last_visited_page: last_visited_page && last_visited_page != "" ? last_visited_page : "main_home"
    }
    
    axios
      .request({
        url: url.update_manage_session_log,
        method: "POST",
        data: JSON.stringify(session_update)
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          functions.handleErrorPopup()
        }
      })
      .catch(error => {
        let error_json = {
          message: error.message,
          catch_error: 1
        }
      });
  },

  update_axios(session_update) {
    axios
      .request({
        url: url.session_update,
        method: "POST",
        data: JSON.stringify(session_update),
        headers: {
          "Authorization": localStorage.getItem("api_auth") ? localStorage.getItem("api_auth") : functions.getAuthKey()
        }
      })
      .then(function (response) {
        if (response.data.failed && response.data.failed == 1) {
          functions.handleErrorPopup()
        }
      })
      .catch(function (error) {

      })
  }
};
export default session;
